import { Injectable } from '@angular/core';
import { Toast, ToastOptions, ToastService, ToastType } from '@msi/cobalt';
import { Store } from "@ngrx/store";
import { toastExpired } from "../../notification/+state/notification.actions";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    activeToasts = new Map<string, Toast>;

    private readonly defaultToastOptions: ToastOptions = {
        autoDismiss: 5000,
        closeButton: true,
    };

    constructor(public toastService: ToastService, private store: Store) {
        toastService.updateContainerConfig({
            maxToastsLimit: 3,
            position: 'top-center',
            maxMessageWidth: 400,
        });
    }

    public dismissToast(ref: string) {
        const foundToast = this.activeToasts.get(ref);
        if (!foundToast) return;
        this.toastService.hideToast(foundToast, false);
        this.activeToasts.delete(ref);
    }

    public showToast(message: string, level: ToastType, options?: ToastOptions, ref?: string) {
        const opts = { ...this.defaultToastOptions, ...options } as ToastOptions;
        opts.onRemove = () => {
            if (ref) {
                this.activeToasts.delete(ref);
                this.store.dispatch(toastExpired({ ref }));
            }
        };
        let toast: Toast;
        switch (level) {
        case ToastType.success:
            toast = this.toastService.success(message, undefined, opts);
            break;
        case ToastType.warning:
            toast = this.toastService.warning(message, undefined, opts);
            break;
        case ToastType.error:
            toast = this.toastService.error(message, undefined, opts);
            break;
        }
        this.activeToasts.set(ref ?? toast.id.toString(), toast);
        return toast;
    }
}
