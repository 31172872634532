/*
 * *****************************************************************************
 *     Copyright (C) 2024 Motorola Solutions, Inc.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Call } from 'CalltakingCoreApi';
import { CallFunctions } from '../../call/util/call-functions';

@Pipe({
    name: 'isActiveParticipant'
})
export class IsActiveParticipantPipe implements PipeTransform {

    transform(call: Call, username: string): boolean {
        return CallFunctions.isActiveParticipant(call, username);
    }

}
