
<!--
  ~ Copyright (C) Motorola Solutions, INC.
  ~ All Rights Reserved.
  -->

<!-- Note: this extends the cobalt template in node_modules/@msi/cobalt/tabs/tab-group/tab-group.component.d.ts -->
<div
    class="msi-tab-labels {{ customClass }}"
    #container
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="horizontal"
    [cdkDropListDisabled]="!draggable">
    <div
        *ngFor="let tab of tabs; let index = index"
        cdkDrag
        [cdkDragDisabled]="tab.disabled"
        cdkDragBoundary=".msi-tab-labels">
        <button
            type="button"
            #buttonTab
            class="msi-tab-label msi-tab-label-{{ index }}"
            [disabled]="tab.disabled"
            (click)="clickTab(index)"
            [ngClass]="{ active: index === selectedIndex, disabled: tab.disabled }"
            [attr.tabIndex]="index === selectedIndex ? -1 : 0">
            <ng-container *ngIf="tab.templateLabel" [ngTemplateOutlet]="tab.templateLabel.tpl"> </ng-container>
            <ng-container *ngIf="!tab.templateLabel">{{ tab.label }}</ng-container>
        </button>
    </div>
</div>
<div class="msi-tab-content" *ngIf="selectedTab">
    <ng-container [ngTemplateOutlet]="selectedTab.content"></ng-container>
</div>
