/*
 * *****************************************************************************
 *     Copyright (C) 2024 Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { TimerService } from '../services/timer.service';
import { CountdownTimeComponent } from '../countdown-time/countdown-time.component';
import dayjs from 'dayjs';

@Component({
    selector: 'msi-countdown-toast',
    template: ` <div class="msi-flex-align">
        <p>{{ message }}</p>
        <div id="toast-countdown">{{ displayedTime$ | async }}</div>
    </div>`,
    styles: [
        `
            #toast-countdown {
                padding-left: var(--msi-ui-spacing-l);
            }
        `
    ]
})
export class CountdownToastComponent extends CountdownTimeComponent implements OnInit {
    @Input()
    public message: string;

    @Input()
    public countdown: number;

    @ContentChild(TemplateRef)
    content: TemplateRef<any>;

    constructor(public timer: TimerService) {
        super(timer);
    }

    ngOnInit(): void {
        console.warn(`countdown:${this.countdown}`);
        this.endTime = dayjs().add(this.countdown, 'ms').toDate();
    }
}
