/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

export class ArrayFunctions {
    public static selectRandom<T>(array: T[]) {
        return array.length ? array[Math.floor(Math.random() * array.length)] : undefined;
    }
}
