/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */



import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { NotificationEffects } from "./+state/notification.effects";

@NgModule({
    imports: [
        EffectsModule.forFeature([NotificationEffects])
    ]
})
export class NotificationModule {}
