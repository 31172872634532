/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { Component } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';

@Component({
    selector: 'msi-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private actions$: Actions) {
        this.recordActionHistory();
    }

    private recordActionHistory() {
        //@ts-ignore
        window.actionHistory = [];
        this.actions$.subscribe((action: Action) => {
            //@ts-ignore
            window.actionHistory.push(action.type);
        } )
    }
}
