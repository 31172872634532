/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IEnvironment } from '../../../assets/config/environment';
import { Observable } from 'rxjs';
import { Meta } from '@angular/platform-browser';

/**
 * Inject this service to use environment variables
 * constructor(private env: EnvironmentService) {}
 */
@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    private readonly CONFIG_URL = 'assets/config/config.json';
    private _env!: IEnvironment;

    constructor(private http: HttpClient, private meta: Meta) {}

    get environment(): IEnvironment {
        return this._env;
    }

    /**
     * Load configuration from /assets/config/config.json
     * during app initialization
     */
    loadConfiguration(): Observable<IEnvironment> {
        return this.http.get<IEnvironment>(this.CONFIG_URL, {
            headers: { 'cache-control': 'no-cache' }
        }).pipe(
            tap((env) => {
                this._env = env;
                if (env.CSP) {
                    this.meta.addTag({
                        'http-equiv': 'Content-Security-Policy',
                        content: env.CSP
                    });
                }
            })
        );
    }
}
