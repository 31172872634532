/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SETTINGS_FEATURE } from './settings.reducer';
import { SupervisorColumn } from '../../supervisor/models/supervisor-column';
import {
    applicationFilter,
    SettingsState,
    SummarySetting,
    SupervisorAgentColumn,
    SupervisorCallColumn,
    SupervisorQueueColumn,
    SupervisorSettingsQueue
} from '../model/settings.model';
import { FilterMenuCategory } from '../../supervisor/models/checkbox-object';
import { agentTableFilters } from '../model/filters';

export const selectSettingsState = createFeatureSelector<SettingsState>(SETTINGS_FEATURE);

export const selectUserSettings = createSelector(selectSettingsState, (state) => state.userSettings);
export const selectSupervisorAgencyColumns = createSelector(selectSettingsState, (state) => state.agencyColumns);
export const selectMonitoredRoleFilter = createSelector(selectSettingsState, (state) => state.monitoredRoleFilter);
export const selectMonitoredQueueFilter = createSelector(selectSettingsState, (state) => state.monitoredQueueFilter);
export const selectAvailableMonitoredRoles = createSelector(selectSettingsState, (state) => state.availableMonitoredRoles);
export const selectFilteredMonitoredRoles = createSelector(selectAvailableMonitoredRoles, selectMonitoredRoleFilter, (availableRoles, roleFilters) =>
    availableRoles.filter((r) => !roleFilters.includes(r?.toLowerCase()))
);
export const selectMaxQueueDisplayRows = createSelector(selectSettingsState, (settings) => settings.maxQueueDisplayRows);

export const selectTheme = createSelector(selectSettingsState, (state) => state?.userSettings?.theme);
export const selectAnnouncementFilters = createSelector(selectSettingsState, (state) => state.menuFilters.announcements);
export const selectActiveCallTableFilters = createSelector(selectSettingsState, (state) => state.menuFilters.callTable);
export const selectActiveAgentTableFilters = createSelector(selectSettingsState, (state) => state.menuFilters.agentTable);

export const selectAgentTableFilters = createSelector(selectSettingsState, (state) => {
    return [
        {
            name: 'Agent role',
            filters: state.availableMonitoredRoles.map((r) => ({
                displayName: r,
                key: r.toLowerCase()
            }))
        },
        ...agentTableFilters
    ] as FilterMenuCategory<applicationFilter>[];
});

export const selectActiveStandaloneCallTableFilters = createSelector(selectSettingsState, (state) => state.menuFilters.standaloneCallTable);
export const selectActiveRecentCallFilters = createSelector(selectSettingsState, (state) => state.menuFilters.recentCalls);
export const selectAudioSettings = createSelector(selectUserSettings, (state) => state.audioSettings);

export const selectPreferredInputDevice = createSelector(selectAudioSettings, (settings) => settings.mainInputDeviceId);

export const selectPreferredOutputDevice = createSelector(selectAudioSettings, (settings) => settings.mainOutputDeviceId);

export const selectPreferredAlertDevice = createSelector(selectAudioSettings, (settings) => settings.alertOutputDeviceId);

export const selectSupervisorCallTableColumns = createSelector(
    selectSupervisorAgencyColumns,
    (agencyColumns) => agencyColumns.filter((a) => a.active && a.tableKey === 'CALLS') as SupervisorCallColumn[]
);

export const selectStandaloneCallTableColumnsConfiguration = createSelector(selectSettingsState, (state) => state.callListConfig);

export const selectStandaloneCallTableColumns = createSelector(selectSettingsState, (state) => state.callListConfig?.columns?.filter((c) => c.active));

export const selectSupervisorAgentTableColumns = createSelector(
    selectSupervisorAgencyColumns,
    (agencyColumns) => agencyColumns.filter((a) => a.active && a.tableKey === 'AGENTS') as SupervisorAgentColumn[]
);

export const selectSupervisorQueueTableColumns = createSelector(
    selectSupervisorAgencyColumns,
    (agencyColumns) => agencyColumns.filter((a) => a.active && a.tableKey === 'QUEUES') as SupervisorQueueColumn[]
);

export const selectSupervisorAgentSummaryColumns = createSelector(selectSupervisorAgencyColumns, (agencyColumns) =>
    agencyColumns.filter((a) => a.active && a.tableKey === 'SUMMARY_AGENTS')
);
export const selectSupervisorAgentSummaryColumnsMap = createSelector(selectSupervisorAgentSummaryColumns, (columns) =>
    columns.reduce<SummarySetting>((arr, curr) => {
        arr[curr.key] = curr;
        return arr;
    }, {})
);

export const selectSupervisorCallSummaryColumns = createSelector(selectSupervisorAgencyColumns, (agencyColumns) =>
    agencyColumns.filter((a) => a.active && a.tableKey === 'SUMMARY_CALLS')
);

export const selectSupervisorCallSummaryColumnsMap = createSelector(selectSupervisorCallSummaryColumns, (columns) =>
    columns.reduce<SummarySetting>((arr, curr) => {
        arr[curr.key] = curr;
        return arr;
    }, {})
);
export const selectSupervisorQueueSummaryColumns = createSelector(selectSupervisorAgencyColumns, (agencyColumns) =>
    agencyColumns.filter((a) => a.active && a.tableKey === 'SUMMARY_QUEUES')
);
export const selectSupervisorQueueSummaryColumnsMap = createSelector(selectSupervisorQueueSummaryColumns, (columns) =>
    columns.reduce<SummarySetting>((arr, curr) => {
        arr[curr.key] = curr;
        return arr;
    }, {})
);

const filterColumns = (columns: SupervisorColumn[]) =>
    columns
        ?.filter((c) => c.thresholds && c.thresholds.some((t) => t.enabled))
        .map((c) => ({
            ...c,
            thresholds: c.thresholds?.filter((t) => t.enabled)
        }));

export const selectSupervisorAgentSummaryColumnsWithThresholds = createSelector(selectSupervisorAgentSummaryColumns, filterColumns);
export const selectSupervisorCallSummaryColumnsWithThresholds = createSelector(selectSupervisorCallSummaryColumns, filterColumns);
export const selectSupervisorQueueSummaryColumnsWithThresholds = createSelector(selectSupervisorQueueSummaryColumns, filterColumns);

export const selectSupervisorAgentTableColumnsWithThresholds = createSelector(selectSupervisorAgentTableColumns, filterColumns);
export const selectSupervisorCallTableColumnsWithThresholds = createSelector(selectSupervisorCallTableColumns, filterColumns);
export const selectSupervisorQueueTableColumnsWithThresholds = createSelector(selectSupervisorQueueTableColumns, filterColumns);

export const selectSupervisorAgentTableColumnsWithThresholdsPolling = createSelector(
    selectSupervisorAgentTableColumnsWithThresholds,
    (activeColumnThresholds) => Boolean(activeColumnThresholds.length)
);
export const selectSupervisorCallTableColumnsWithThresholdsPolling = createSelector(
    selectSupervisorCallTableColumnsWithThresholds,
    (activeColumnThresholds) => Boolean(activeColumnThresholds.length)
);
export const selectSupervisorQueueTableColumnsWithThresholdsPolling = createSelector(
    selectSupervisorQueueTableColumnsWithThresholds,
    (activeColumnThresholds) => Boolean(activeColumnThresholds.length)
);
export const selectSupervisorAgentSummaryColumnsWithThresholdsPolling = createSelector(
    selectSupervisorAgentSummaryColumnsWithThresholds,
    (activeColumnThresholds) => Boolean(activeColumnThresholds.length)
);
export const selectSupervisorCallSummaryColumnsWithThresholdsPolling = createSelector(
    selectSupervisorCallSummaryColumnsWithThresholds,
    (activeColumnThresholds) => Boolean(activeColumnThresholds.length)
);
export const selectSupervisorQueueSummaryColumnsWithThresholdsPolling = createSelector(
    selectSupervisorQueueSummaryColumnsWithThresholds,
    (activeColumnThresholds) => Boolean(activeColumnThresholds.length)
);

export const selectCallSummaryScheme = createSelector(selectSettingsState, (state) => state.summarySchemes.find((s) => s.schemeType === 'SUMMARY_CALLS'));
export const selectAgentSummaryScheme = createSelector(selectSettingsState, (settings) =>
    settings.summarySchemes.find((s) => s.schemeType === 'SUMMARY_AGENTS')
);
export const selectQueueSummaryScheme = createSelector(selectSettingsState, (settings) =>
    settings.summarySchemes.find((s) => s.schemeType === 'SUMMARY_QUEUES')
);

export const selectAllSupervisedQueues = createSelector(selectSettingsState, (state) => state.supervisedQueues);

export const selectSupervisedQueueMap = createSelector(selectAllSupervisedQueues, (queues) =>
    queues.reduce<Record<string, SupervisorSettingsQueue>>((a, c) => ({ [c.key]: c, ...a }), {})
);

export const selectSupervisorRoleId = createSelector(selectSettingsState, (state) => state.selectedRoleId);

export const selectAncillaryServiceVersions = createSelector(selectSettingsState, (state) => state.softwareVersions.filter((s) => s.name === 'Location Service' || s.name === 'Skipper API Gateway'))
