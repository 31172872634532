/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

export class DeviceAudioContextInterface {
    protected readonly audioContext: AudioContext;
    protected readonly device: MediaDeviceInfo;
    public channel: number;
    public groupId: string;

    constructor(device: MediaDeviceInfo, channel = 0) {
        this.channel = channel;
        this.device = device;
        this.groupId = device.groupId;
        console.debug(`Creating audio context associated with ${device.label} ${this.channel ? "channel: " + this.channel : ""}`);
        this.audioContext = new AudioContext();
    }

    public checkContext() {
        if (this.audioContext.state === 'suspended') {
            console.warn(`Audio context associated with ${this.device.label} ${this.channel ? "channel: " + this.channel : ""} is suspended. Attempting to resume.`);
            this.audioContext
                .resume()
                .then(() => console.warn(`Successfully started audio context associated with ${this.device.label} ${this.channel ? "channel: " + this.channel : ""}`))
                .catch((err) => console.error(`Unable to start audio context associated with ${this.device.label} ${this.channel ? "channel: " + this.channel : ""} {}`, err));
        }
    }
}
