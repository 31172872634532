/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

export interface DirectoryItem {
    type: DirectoryItemType;
    uuid: string;
    name: string;
    icon: string;
    color: string;
}

export interface DirectoryItemReference {
    type: DirectoryItemType;
    uuid: string;
}

export enum DirectoryItemType {
    BUTTON = 'BUTTON',
    FOLDER = 'FOLDER',
    // UI exclusive
    ADR_BUTTON = 'ADR_BUTTON'
}
