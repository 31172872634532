/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { WindowState } from '../model/window-state';

/**
 *
 * Provides methods to store/retrieve window component position, size, and if they are open.
 * Uses local-storage
 */
@Injectable({
    providedIn: 'root'
})
export class WindowService {

    private keyGen = (id: string) => `${id}-state`;
    constructor() {}

    /**
     * Get current window settings from state or window config object based on parameters
     * If no settings yet exist, it will return defaults based on the host window
     * @param key
     */
    public getWindowState(key: string): WindowState {
        const previousSettings = localStorage.getItem(this.keyGen(key));
        if (previousSettings) {
            return JSON.parse(localStorage.getItem(this.keyGen(key))) as WindowState;
        }
        return {
            docked: true,
            x: window.screenX,
            y: window.screenY,
            width: window.outerWidth,
            height: window.outerHeight
        };
    }

    /**
     * Saves window position and size state to localStorage
     * @param key
     * @param windowRef
     */
    public saveWindowState(key: string, windowRef: Window): WindowState {
        let state = {
            ...this.getWindowState(key),
            x: windowRef.screenX,
            y: windowRef.screenY,
            height: windowRef.outerHeight,
            width: windowRef.outerWidth
        };
        console.debug(`Saving window state ${key}: ${JSON.stringify(state, undefined, 1)}`);
        localStorage.setItem(this.keyGen(key), JSON.stringify(state));
        return state;
    }

    public updateDockedState(key: string, docked: boolean) {
        let state = { ...this.getWindowState(key), docked: docked };
        console.debug(`Updating window docked state ${key}: ${JSON.stringify(state, undefined, 1)}`);
        localStorage.setItem(this.keyGen(key), JSON.stringify(state));
    }
}
