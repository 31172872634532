/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'memoizedFunction'
})
export class MemoizedFunctionPipe implements PipeTransform {

    public transform(value: any, handler: (value?: any) => any, context?: any): any {
        if (context) {
            return handler.call(context, value);
        }
        return handler(value);
    }

}
