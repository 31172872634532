/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { DeviceAudioContextInterface } from './device-audio-context-interface';

export class InputDeviceInterface extends DeviceAudioContextInterface {
    protected readonly mixerNode: GainNode;
    protected readonly mediaStreamAudioDestinationNode: MediaStreamAudioDestinationNode;
    protected mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
    protected volume = 5;
    public mediaStream: MediaStream;

    constructor(device: MediaDeviceInfo, channel = 0) {
        super(device, channel);
        this.mixerNode = this.audioContext.createGain();
        this.mediaStreamAudioDestinationNode = this.audioContext.createMediaStreamDestination();
        this.mixerNode.connect(this.mediaStreamAudioDestinationNode);
    }

    public init() {
        return navigator.mediaDevices
            .getUserMedia(this.getMediaConstraints(this.device.deviceId))
            .then((mediaStream) => this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(mediaStream))
            .then(() => this.checkContext())
            .then(() => this.connect())
            .then(() => this.mediaStream = this.mediaStreamAudioDestinationNode.stream)
            .then(() => console.debug(`Acquired media stream ${this.mediaStreamAudioDestinationNode.stream.id} from device: ${this.device.label} ${this.channel ? "channel: " + this.channel : ""}`))
            .catch((e) => console.error(`Failed to get media stream for device: ${this.device.label} ${this.channel ? "channel: " + this.channel : ""}`, e));
    }

    /* Override to connect additional nodes in between the source and the mixer such as a channel splitter */
    protected connect() {
        this.mediaStreamAudioSourceNode.connect(this.mixerNode);
    }

    public destroy() {
        this.mediaStreamAudioSourceNode?.disconnect();
        this.mixerNode.disconnect();
        this.audioContext
            .close()
            .then(() => console.debug(`Destroyed audio context associated with ${this.device.label} ${this.channel ? "channel: " + this.channel : ""}`))
            .catch(() => console.error(`Failed to destroy audio context associated with ${this.device.label} ${this.channel ? "channel: " + this.channel : ""}`));
    }

    public mute() {
        console.debug(`Muting ${this.device.label} ${this.channel ? "channel: " + this.channel : ""}`);
        this.mixerNode.gain.setValueAtTime(0, this.audioContext.currentTime);
    }

    public unmute() {
        console.debug(`Un-muting ${this.device.label} ${this.channel ? "channel: " + this.channel : ""}`);
        this.mixerNode.gain.setValueAtTime(1, this.audioContext.currentTime);
    }

    public setAudioVolume(volume: number) {
        this.volume = volume / 5;
        console.debug(`Setting gain to ${this.volume} on ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
        this.mixerNode?.gain.setValueAtTime(this.volume, this.audioContext.currentTime);
    }

    /**
     * Note: turn off echo cancellation to prevent chrome from potentially auto-mixing channels
     * Note: turn off auto-gain control to try and prevent windows/chrome from mucking with the os mic levels
     * **/
    private getMediaConstraints = (deviceId: string) => ({
        audio: {
            deviceId: {
                exact: deviceId
            },
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false
        },
        video: false
    });

}
