/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */


import { createAction, props } from '@ngrx/store';
import { ToastOptions, ToastType } from '@msi/cobalt';

export const displayToastNotification = createAction(
    '[Notification] Display',
    props<{ message: string, level: ToastType, options?: ToastOptions }>()
);

export const displayTimedToastNotification = createAction(
    '[Notification] Display Timed',
    props<{ message: string, level: ToastType, options?: ToastOptions, countdown: number, ref?: string }>()
);

export const toastExpired = createAction(
    '[Notification] Toast Expired',
    props<{ ref: string }>()
);

export const clearToastNotification = createAction('[Notification] Clear', props<{ ref: string }>());
