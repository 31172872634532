/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { LocationEvent } from '../model/location';
import { LocationDisplayTemplate } from '../model/location-item-template';
import { ServiceResponders } from '../model/service-responders';

export const locationEffectsInitialized = createAction('[Location] Effects Initialized');
export const fetchLocation = createAction('[Location] Fetch', props<{ callId: string }>());
export const fetchLocationSuccess = createAction('[Location] Fetch Success', props<{ locations: LocationEvent[] }>());
export const fetchLocationFail = createAction('[Location] Fetch Fail', props<{ payload: string }>());
export const receivedLocationEvent = createAction('[Location] Received Location Event', props<{ locationEvent: LocationEvent }>());
export const updateStandardLocation = createAction('[Location] Update Standard Location', props<{ locationEvent: LocationEvent; enhanced?: boolean }>());
export const updateEnhancedLocation = createAction('[Location] Update Enhanced Location', props<{ locationEvent: LocationEvent }>());
export const purgeLocation = createAction('[Location] Purge Location', props<{ uuid: string }>());

export const rebid = createAction('[Location] Rebid', props<{ callId: string }>());
export const rebidSuccess = createAction('[Location] Rebid Success', props<{ callId: string }>());
export const rebidFail = createAction('[Location] Rebid Failure', props<{ payload: string }>());

export const manualQuery = createAction('[Location] Manual Query', props<{ number: string }>());
export const manualQuerySuccess = createAction('[Location] Manual Query Success', props<{ number: string; locationEvent: LocationEvent }>());
export const manualQueryFail = createAction('[Location] Manual Query Failure', props<{ number: string; payload: string }>());

export const fetchLocationLayout = createAction('[Location] Layout Fetch');
export const fetchLocationLayoutSuccess = createAction('[Location] Layout Fetch Success', props<{ response: LocationDisplayTemplate }>());
export const fetchLocationLayoutFail = createAction('[Location] Layout Fetch Failure', props<{ payload: string }>());

export const fetchServiceResponders = createAction('[Location] Service Responders Fetch');
export const fetchServiceRespondersSuccess = createAction('[Location] Service Responders Fetch Success', props<{ response: ServiceResponders }>());
export const fetchServiceRespondersFail = createAction('[Location] Service Responders Fetch Failure', props<{ payload: string }>());
