import { Pipe, PipeTransform } from '@angular/core';
import { QueueMetricObj } from "../../supervisor/queue-metrics/+state/metrics.model";

@Pipe({
  name: 'filteredQueues'
})
export class FilteredQueuesPipe implements PipeTransform {

  transform<T extends QueueMetricObj>(queues: T[], filter: string) {

    if (filter && filter.length > 0) {
      return queues.filter((a) =>
          a && (a.queueId || '')
              .toLowerCase()
              .includes(filter.toLowerCase()));
    }
    return queues as T[];
  }
}
