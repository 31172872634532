<!--
  COPYRIGHT Motorola Solutions, INC.
  ALL RIGHTS RESERVED.
  MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
-->

<msi-common-header [search]="false">
    <msi-header-left-plugin-area class="msi-common-header-left-plugin-area" [applicationName]="appName"> </msi-header-left-plugin-area>
    <msi-header-right-plugin-area>
        <cc-session-manager class="msi-session-manager" [renderLogout]="false" *ngIf="this.env.environment.featureFlags?.ccAdminIntegrated">
            <ng-container *ngIf="this.env.environment.featureFlags?.roleSwitcherEnabled && token?.roles?.length">
                <msi-dropdown-item [msiDropdownTriggerFor]="rolesDropdown" id="role-switcher-enabled" class="msi-flex-row justify-content-between">
                    <div class="msi-flex-col role-switcher-content">
                        <div class="session-item-header">Role</div>
                        <h5 class="session-item-value">{{ token.preferred_role }}</h5>
                    </div>
                </msi-dropdown-item>
                <msi-dropdown #rolesDropdown [xPosition]="'right'">
                    <ng-container *ngFor="let role of filteredRoles">
                        <msi-dropdown-item (click)="changeRole(role.roleName)">{{ role.roleName }}</msi-dropdown-item>
                    </ng-container>
                </msi-dropdown>
            </ng-container>
            <ng-container>
                <msi-dropdown-item (click)="signOut(logoutModal)" id="logout">
                    <div>
                        <div>Sign out</div>
                    </div>
                </msi-dropdown-item>
            </ng-container>
        </cc-session-manager>
    </msi-header-right-plugin-area>
</msi-common-header>
<div id="unauthorized-message">
    <msi-icon [size]="92" name="ic_lock_closed"></msi-icon>
    <div class="msi-text">
        <h1>Unauthorized</h1>
        <h5>The current role does not have access to this application. Switch agency or role.</h5>
    </div>
</div>
<ng-template #logoutModal>
    <msi-modal>
        <msi-modal-header>
            <h5>Sign out</h5>
        </msi-modal-header>
        <msi-modal-content>
            <p>Do you want to sign off from the application?</p>
        </msi-modal-content>
        <msi-modal-actions align="end">
            <button class="msi-btn msi-btn-action" (click)="cancel()">Stay in the application</button>
            <button id="signout" class="msi-btn" (click)="confirm()">Sign out</button>
        </msi-modal-actions>
    </msi-modal>
</ng-template>
