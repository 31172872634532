/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { SingleChannelInputDeviceInterface } from './single-channel-input-device-interface';
import { DeviceEndpointBinder } from 'web-audio-module/dist/peripheral-configuration-tool/i-deviceendpoint-binder';
import { Store } from '@ngrx/store';
import { selectCcHubInputOnCall } from '../+state/call.selectors';
import {
    selectCcHubCallAudioInputConnected,
    selectCCHubInputMuted,
    selectCCHubInputMuteOnAnswer,
    selectCCHubPeripheralVolume
} from '../+state/cchub.selectors';
import { Subscription } from 'rxjs';
import { muteAction } from '../+state/cchub.actions';

export class CcHubInputDeviceInterface extends SingleChannelInputDeviceInterface {
    private readonly peripheral: string;
    private muteSubscription: Subscription;
    private volumeSubscription: Subscription;
    private connectedSubscription: Subscription;
    private onCallSubscription: Subscription;
    private muteOnAnswerSubscription: Subscription;
    private store: Store;
    private _connected: boolean;
    private _onCall: boolean;
    private _muteOnAnswer: boolean;

    constructor(peripheral: DeviceEndpointBinder, store: Store) {
        super(peripheral.deviceendpoint, peripheral.deviceendpoint.channel);
        this.peripheral = peripheral.peripheral;
        this.store = store;

    }

    init(): Promise<void> {
        return super.init().then(() => {
            this.connectedSubscription = this.store.select(selectCcHubCallAudioInputConnected(this.peripheral)).subscribe((connected) => this.connected = connected);
            this.muteSubscription = this.store.select(selectCCHubInputMuted(this.peripheral)).subscribe((isMuted) => isMuted ? this.mute() : this.unmute());
            this.onCallSubscription = this.store.select(selectCcHubInputOnCall(this.peripheral)).subscribe((onCall) => this.onCall = onCall);
            this.volumeSubscription = this.store.select(selectCCHubPeripheralVolume(this.peripheral)).subscribe((volume) => this.setAudioVolume(volume));
            this.muteOnAnswerSubscription = this.store.select(selectCCHubInputMuteOnAnswer(this.peripheral)).subscribe((muteOnAnswer) =>  this.muteOnAnswer = muteOnAnswer);
        });
    }

    destroy() {
        super.destroy();
        this.connectedSubscription?.unsubscribe();
        this.muteSubscription?.unsubscribe();
        this.volumeSubscription?.unsubscribe();
        this.onCallSubscription?.unsubscribe();
        this.muteOnAnswerSubscription?.unsubscribe();
    }

    get connected() {
        return this._connected;
    }

    set connected(isConnected: boolean) {
        this._connected = isConnected;
        this.connected ? this.enable() : this.disable();
        this.computeAutoMuteState();
    }

    public enable() {
        console.debug(`Enabling input from ${this.peripheral}`);
        this.mixerNode.connect(this.mediaStreamAudioDestinationNode);
    }

    public disable() {
        console.debug(`Disabling input from ${this.peripheral}`);
        this.mixerNode.disconnect(this.mediaStreamAudioDestinationNode);
    }

    get onCall() {
        return this._onCall;
    }

    set onCall(val: boolean) {
        this._onCall = val;
        this.computeAutoMuteState();
    }

    get muteOnAnswer() {
        return this._muteOnAnswer;
    }

    set muteOnAnswer(val: boolean) {
        this._muteOnAnswer = val;
        this.computeAutoMuteState();
    }

    private computeAutoMuteState() {
        let isMuted = !this.connected || !this.onCall || (this.connected && this.muteOnAnswer && this.onCall);
        this.store.dispatch(muteAction({ peripheral: this.peripheral, isMuted: isMuted }));
    }
}
