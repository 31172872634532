import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ExcludedUrlRegex } from 'keycloak-angular/lib/core/interfaces/keycloak-options';

@Injectable({
    providedIn: 'root'
})
export class KeycloakBearerInterceptor implements HttpInterceptor {
    private accessToken: string | null;

    constructor(private keycloakService: KeycloakService) {
        this.accessToken = null;
        this.keycloakService.keycloakEvents$.subscribe((event) => {
            if (event.type === KeycloakEventType.OnAuthSuccess || KeycloakEventType.OnAuthRefreshSuccess) {
                keycloakService.getToken().then((token) => {
                    if (token) {
                        this.accessToken = token;
                    }
                });
            }
        });
    }

    private isUrlExcluded({ method, url }: HttpRequest<unknown>, { urlPattern, httpMethods }: ExcludedUrlRegex): boolean {
        let httpTest = true;

        if (httpMethods) {
            httpTest = httpMethods.length === 0 || httpMethods.join().indexOf(method.toUpperCase()) > -1;
        }

        const urlTest = urlPattern.test(url);

        return httpTest && urlTest;
    }

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const { excludedUrls } = this.keycloakService;

        const shallPass: boolean = excludedUrls?.findIndex((item) => this.isUrlExcluded(req, item)) > -1;
        if (shallPass) {
            return next.handle(req);
        }

        if (this.accessToken != null) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ` + this.accessToken,
                    'cache-control': 'no-cache'
                }
            });
        }
        return next.handle(req);
    }
}
