/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Call, LocalParticipant } from 'CalltakingCoreApi';
import { SortFunctions } from '../util/sort-functions';
import { CallFunctions } from '../util/call-functions';

@Pipe({
    name: 'positionName'
})
export class PositionNamePipe implements PipeTransform {
    transform(call: Call): string {
        return Object.values(call.participants)
            .filter((p) => !p.silentMonitor && (!p.leftOn || CallFunctions.isHeldParticipant(call, p.username)))
            .sort(SortFunctions.joinedOnSort)
            .map((p: LocalParticipant) => p.positionName)
            .join('; ');
    }
}
