/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Directive, ElementRef, HostListener } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

@Directive({
    selector: '[customOverlayContainer]'
})
export class CustomOverlayContainerDirective {
    constructor(
        protected elementReference: ElementRef,
        protected cdkOverlayContainer: OverlayContainer
    ) {
        this.elementReference    = elementReference;
        this.cdkOverlayContainer = cdkOverlayContainer;
        console.debug(`Creating new overlay container: ${this.elementReference.nativeElement?.id}`);
        // @ts-ignore
        this.cdkOverlayContainer['customCreateContainer'](this.elementReference.nativeElement);
    }

    @HostListener('mouseenter', ['$event']) onClick($event: MouseEvent){
        $event?.stopPropagation();
        $event?.preventDefault();
        let overlayContainer = ($event.currentTarget as HTMLElement).querySelector('.cdk-overlay-container');
        if (overlayContainer) {
            // @ts-ignore
            this.cdkOverlayContainer['setContainerElement'](overlayContainer);
        }
    }
}
