/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { CChubState } from './cchub.reducer';
import { IAudioConfiguration } from 'wam-wrapper/definitions';
import { CchubService } from '../services/cchub.service';
import { AudioDevicesRoles } from 'wam-wrapper';
import { AudioDevicesRolesInternal } from 'web-audio-module/dist/e-audio-devices-roles.enum';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectAudioSettings } from '../../settings/+state/settings.selectors';

export const CCHUB_FEATURE = 'cchub';

export const selectCCHubState = createFeatureSelector<CChubState>(CCHUB_FEATURE);

export const selectCCHubEffectInitialized = createSelector(selectCCHubState, (state) => state.effectsInitialized);

export const selectCCHubAudioDeviceConnectionStatus = createSelector(selectCCHubState, (state) => state.ccHubAudioDeviceConnectionStatus);
export const selectCcHubDeviceEndpointBinders = createSelector(selectCCHubAudioDeviceConnectionStatus, (binders) => Object.values(binders));
export const selectConnectedCcHubDeviceEndpointBinders = createSelector(selectCcHubDeviceEndpointBinders, (binders) =>
    binders.filter((binder) => binder.isConnected)
);
export const selectConnectedCcHubDeviceEndpointInputBinders = createSelector(selectConnectedCcHubDeviceEndpointBinders, (binders) =>
    binders.filter((binder) => binder.deviceendpoint.kind === 'audioinput')
);
export const selectConnectedCcHubDeviceEndpointOutputBinders = createSelector(selectConnectedCcHubDeviceEndpointBinders, (binders) =>
    binders.filter((binder) => binder.deviceendpoint.kind === 'audiooutput')
);
export const selectCcHubDeviceEndpointInputBinders = createSelector(selectCcHubDeviceEndpointBinders, (binders) =>
    binders.filter((binder) => binder.deviceendpoint.kind === 'audioinput')
);
export const selectCcHubDeviceEndpointOutputBinders = createSelector(selectCcHubDeviceEndpointBinders, (binders) =>
    binders.filter((binder) => binder.deviceendpoint.kind === 'audiooutput')
);

export const selectConnectedCcHubCallAudioInputs = createSelector(selectConnectedCcHubDeviceEndpointInputBinders, (binders) =>
    binders.filter((binder) => CchubService.DEFAULT_AUDIO_INPUT_PERIPHERALS.includes(binder.peripheral as AudioDevicesRoles | AudioDevicesRolesInternal))
);

export const selectCcHubCallAudioInputs = createSelector(selectCcHubDeviceEndpointInputBinders, (binders) =>
    binders.filter((binder) => CchubService.DEFAULT_AUDIO_INPUT_PERIPHERALS.includes(binder.peripheral as AudioDevicesRoles | AudioDevicesRolesInternal))
);

export const selectCcHubCallAudioInputConnected = (peripheral: string) =>
    createSelector(selectCcHubCallAudioInputs, (inputs) => {
        return Boolean(inputs.find((input) => input.peripheral === peripheral)?.isConnected);
    });

export const selectConnectedCcHubDefaultAudioOutputs = createSelector(selectConnectedCcHubDeviceEndpointOutputBinders, (binders) =>
    binders.filter((binder) => CchubService.DEFAULT_AUDIO_OUTPUT_PERIPHERALS.includes(binder.peripheral as AudioDevicesRoles | AudioDevicesRolesInternal))
);

export const selectCcHubDefaultAudioOutputs = createSelector(selectCcHubDeviceEndpointOutputBinders, (binders) =>
    binders.filter((binder) => CchubService.DEFAULT_AUDIO_OUTPUT_PERIPHERALS.includes(binder.peripheral as AudioDevicesRoles | AudioDevicesRolesInternal))
);

export const selectCcHubRecorderAudioOutputs = createSelector(selectCcHubDeviceEndpointOutputBinders, (binders) =>
    binders.filter((binder) => CchubService.RECORDER_AUDIO_OUTPUT_PERIPHERALS.includes(binder.peripheral as AudioDevicesRoles | AudioDevicesRolesInternal))
);

export const selectCcHubCallAudioOutputs = createSelector(selectCcHubDefaultAudioOutputs, selectCcHubRecorderAudioOutputs, (defaultOutputs, recorderOutputs) =>
    defaultOutputs.concat(recorderOutputs)
);

export const selectCcHubAudioOutputConnected = (peripheral: string) =>
    createSelector(selectCcHubCallAudioOutputs, (outputs) => {
        return Boolean(outputs.find((output) => output.peripheral === peripheral)?.isConnected);
    });

export const selectCCHubHeadsetOverride = createSelector(selectCCHubState, (state) => state.ccHubHeadsetOverride);

export const selectCCHubAttached = createSelector(selectCCHubState, selectCCHubHeadsetOverride, (state, override) => state.ccHubAttached && !override);

export const selectCCHubMuteStatus = createSelector(selectCCHubState, (state) => state.muteStatus);

export const selectCCHubInputMuted = (peripheral: string) =>
    createSelector(selectCCHubMuteStatus, (muteStatus) => muteStatus[peripheral]?.muted);

export const selectCCHubInputMuteOnAnswer = (peripheral: string) =>
    createSelector(selectCCHubMuteStatus, (muteStatus) => Boolean(muteStatus[peripheral]?.muteOnAnswer));

export const selectCCHubVolumeStatus = createSelector(selectCCHubState, selectAudioSettings, (state, audioSettings) => ({ ...state.volumeStatus, ...audioSettings.volumeStatus}));

export const selectCCHubPeripheralVolume = (peripheral: string) =>
    createSelector(selectCCHubVolumeStatus, selectAudioSettings, (volumeStatus, audioSettings) =>
        audioSettings.volumeStatus && audioSettings.volumeStatus[peripheral] ? audioSettings.volumeStatus[peripheral].volume : volumeStatus[peripheral] ? volumeStatus[peripheral].volume : 5);


export const selectCCHubSerialNumber = createSelector(selectCCHubState, (state) => state.ccHubSn);

export const selectCCHubDeviceEndpointBinders = createSelector(selectCCHubState, (state) => state.endpointBinders);

export const selectWamConfig = createSelector(selectCCHubState, (state) => state.wamConfig as IAudioConfiguration);

export const selectInitializedWamConfig = createSelector(
    selectWamConfig,
    selectCCHubSerialNumber,
    selectCCHubDeviceEndpointBinders,
    (wamConfig, sn, endpointBinders) =>
        sn && wamConfig && endpointBinders
            ? { ...wamConfig, audioPeripherals: { ...wamConfig.audioPeripherals, deviceEndpointBinders: endpointBinders } }
            : undefined
);

export const selectCCHubConnected = createSelector(selectCCHubState, (state) => state.ccWebUsbConnectionStatus.connectionState);

export const selectCCHubInitialized = createSelector(selectCCHubState, (state) => state.ccHubInitialized);

export const selectFirmwareVersion = createSelector(selectCCHubState, (state) => state.firmwareVersion);

export const selectCCHubConnectedAndInitialized = createSelector(
    selectCCHubConnected,
    selectCCHubInitialized,
    (connected, initialized) => connected && initialized
);

export const selectCCHubHeadset1Connected = createSelector(selectCCHubState, (state) => state.ccHubUsbButtons['HEADSET1_PTT']?.status === 'ADDED');

export const selectCCHubHeadset2Connected = createSelector(selectCCHubState, (state) => state.ccHubUsbButtons['HEADSET2_PTT']?.status === 'ADDED');

export const selectRpiHeadsetOverride = createSelector(selectCCHubState, (state) => state.ccHubRadioHeadsetOverride);

// workaround for external radio headset presence detection, use EPE state to signal radio headset is 'plugged' or 'unplugged' corresponding to 'pressed' and 'released'
// Note: RPI should be ignored/excluded from call if HEADSET_1 is plugged.
export const selectRadioHeadsetConnected = createSelector(
    selectCCHubState,
    selectCCHubHeadset1Connected,
    selectRpiHeadsetOverride,
    (state, headset1, rpiHeadsetOverride) => (!headset1 && state.ccHubUsbButtons['EPE_PTT']?.state === 'PRESSED') || rpiHeadsetOverride
);

const _selectRpiExternalJackSenseState = createSelector(
    selectCCHubConnectedAndInitialized,
    selectCCHubHeadset1Connected,
    (ccHubConnectedAndInitialized, ccHubHeadsetDetected) => (ccHubConnectedAndInitialized ? ccHubHeadsetDetected : undefined)
);

export const selectRpiExternalJackSenseState = pipe(
    select(_selectRpiExternalJackSenseState),
    filter((val) => val !== undefined),
    map((val) => val)
);

export const selectHeadsetConnected = createSelector(
    selectCCHubHeadset1Connected,
    selectRadioHeadsetConnected,
    selectCCHubHeadset2Connected,
    (ccHubHeadset1Connected, ccHubHeadset2Connected, radioHeadsetConnected) => radioHeadsetConnected || ccHubHeadset1Connected || ccHubHeadset2Connected
);

export const selectHeadsetDisconnectedAndTimedOut = createSelector(
    selectCCHubState,
    (state) => state.headsetDisconnectedAndTimedOut
);

export const selectIsHubConnectedAndHeadsetDisconnected = createSelector(
    selectCCHubAttached,
    selectHeadsetConnected,
    selectHeadsetDisconnectedAndTimedOut,
    (hubAttached, headsetConnected, disconnectedAndTimedOut) => hubAttached && !headsetConnected && disconnectedAndTimedOut
);

export const selectAlertOutputToDevice = createSelector(selectCCHubState, selectAudioSettings, (state, audioSettings) => audioSettings.alertToDevice !== undefined ? audioSettings.alertToDevice : state.alertToDevice);
export const selectAlertOutputToHeadsets = createSelector(selectCCHubState, selectAudioSettings, (state, audioSettings) => audioSettings.alertToHeadsets !== undefined ? audioSettings.alertToHeadsets : state.alertToHeadsets);
export const selectAlertOutputEnabled = (peripheral: string) => createSelector(selectConnectedCcHubDefaultAudioOutputs, selectAlertOutputToHeadsets, (outputs, alertToHeadsets) =>
    Boolean(outputs.find((output) => output.peripheral === peripheral) && alertToHeadsets));
export const selectIrrOutputToDevice = createSelector(selectCCHubState, selectAudioSettings, (state, audioSettings) => audioSettings.irrToDevice !== undefined ? audioSettings.irrToDevice : state.irrToDevice);
export const selectIrrOutputToHeadsets = createSelector(selectCCHubState, selectAudioSettings, (state, audioSettings) => audioSettings.irrToHeadsets !== undefined ? audioSettings.irrToHeadsets : state.irrToHeadsets);
export const selectIrrOutputEnabled = (peripheral: string) => createSelector(selectConnectedCcHubDefaultAudioOutputs, selectIrrOutputToHeadsets, (outputs, irrToHeadsets) =>
    Boolean(outputs.find((output) => output.peripheral === peripheral) && irrToHeadsets));
