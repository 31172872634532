
export enum HotKeyAction {
    'ANSWER' = "ANSWER",
    'BACKSPACE' = 'BACKSPACE',
    'CALLBACK_SELECTED' = 'CALLBACK_SELECTED',
    'CLEAR' = 'CLEAR',
    'CONFERENCE_RELEASE' = 'CONFERENCE_RELEASE',
    'DEAFEN' = 'DEAFEN',
    'DIAL' = 'DIAL',
    'DIAL_CONTACT' = 'DIAL_CONTACT',
    'HOLD' = 'HOLD',
    'INPUT' = 'INPUT',
    'MUTE' = 'MUTE',
    'READY_TOGGLE' = 'READY_TOGGLE',
    'REBID' = 'REBID',
    'RELEASE' = 'RELEASE',
    'STATUS' = 'STATUS',
    'VOLUME_UP' = 'VOLUME_UP',
    'VOLUME_DOWN' = 'VOLUME_DOWN',
}

export interface HotKey {
    "action": HotKeyAction,
    "params": string[],
    "combo": string
}
