/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Call } from 'CalltakingCoreApi';
import { CallFunctions } from '../../call/util/call-functions';

@Pipe({
    name: 'isSilentMonitoring'
})
export class IsSilentMonitoringPipe implements PipeTransform {

    transform(call: Call, username: string): boolean {
        return CallFunctions.isSilentMonitoring(call, username);
    }

}
