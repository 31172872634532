/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CdkPortal, DomPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { selectTheme } from '../../settings/+state/settings.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { WindowService } from './services/window.service';
import { Theme } from '../../settings/model/theme';
import { displayToastNotification } from "../../notification/+state/notification.actions";
import { ToastType } from "@msi/cobalt";

@Component({
    selector: 'msi-window',
    templateUrl: './window.component.html',
    styleUrls: ['./window.component.scss']
})
export class WindowComponent implements AfterViewInit, OnDestroy {
    @Input()
    public id: string;

    @Input()
    public title!: string;

    @Input()
    public width!: number;

    @Input()
    public height!: number;

    @Output()
    public closed: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(CdkPortal) portal!: TemplatePortal;

    private outlet!: PortalOutlet;
    private externalWindow: Window | null = null;
    private styleObserver!: MutationObserver;
    private _unsubscribe$ = new Subject<void>();

    constructor(
        private store: Store,
        private windowService: WindowService
    ) {}

    ngAfterViewInit(): void {
        this.open();
    }

    ngOnDestroy() {
        window.removeEventListener('beforeunload', this.primaryWindowClosedEventHandler);

        if (this.externalWindow) {
            this.externalWindow.removeEventListener('beforeunload', this.externalWindowClosedHandler);
            this.externalWindow?.close();
        }

        this.styleObserver?.disconnect();
        this.outlet?.detach();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    private open() {
        this.askForMultiWindowApiPermission().then(
            () => {
                const settings = this.windowService.getWindowState(this.id);

                // Create an external window
                this.externalWindow = window.open('', '', `height=${settings.height},width=${settings.width},top=${settings.y}, screenX=${settings.x}`);

                if (!this.externalWindow) {
                    const existingWindowState = this.windowService.getWindowState(this.id);

                    // Check if window was docked last session
                    if (existingWindowState && !existingWindowState.docked) {
                        this.windowService.updateDockedState(this.id, true);
                    }

                    this.closed.emit(true);

                    //POPUP BLOCKED
                    this.store.dispatch(displayToastNotification({ message: 'Monitoring cannot be opened due to browser policy', level: ToastType.error }));
                    console.warn('External window open was blocked by browser policy');
                    return;
                }

                // Find and set baseRef such that relative imports function as expected
                let base = document.createElement('base');
                base.href = window.location.href;
                this.externalWindow.document.head.appendChild(base);

                // Clone light/dark theme
                this.monitorThemeChanges();

                // Copy stylesheet link from parent window
                document
                    .querySelectorAll('link[rel="stylesheet"]')
                    .forEach((linkElement) => this.externalWindow.document.head.appendChild(linkElement.cloneNode(true)));

                // Copy component styles and monitor for changes happening in the main window
                this.cloneComponentStyles();
                this.styleObserver = new MutationObserver(() => this.cloneComponentStyles());
                this.styleObserver.observe(document.querySelector('head'), { childList: true });

                // Copy title from parent window
                this.externalWindow.document.title = `${document.title} - ${this.title}`;

                // Create a PortalOutlet with the body of the new window document
                this.outlet = new DomPortalOutlet(this.externalWindow.document.body);
                this.outlet.attach(this.portal);

                this.externalWindow.addEventListener('beforeunload', this.externalWindowClosedHandler);
                window.addEventListener('beforeunload', this.primaryWindowClosedEventHandler);
            });
    }

    private primaryWindowClosedEventHandler = () => this.ngOnDestroy();

    private externalWindowClosedHandler = () => {
        this.windowService.saveWindowState(this.id, this.externalWindow);
        this.closed.emit(true);
    };

    private async askForMultiWindowApiPermission() {
        const windowRef = window as any;
        try {
            // Prompt user for permission to access new multi-window API
            await windowRef.getScreenDetails();
        } catch (err) {
            console.error(`Permission not granted for multi-window support`, err);
        }
    }

    private cloneComponentStyles() {
        // clear existing styles
        this.externalWindow?.document.head.querySelectorAll('style').forEach((styleElement) => this.externalWindow?.document.head.removeChild(styleElement));
        // Copy styles from parent window (ensures the component you are opening has its styles actually loaded in html)
        document.querySelectorAll('style').forEach((styleElement) => this.externalWindow?.document.head.appendChild(styleElement.cloneNode(true)));
    }

    private monitorThemeChanges() {
        this.store
            .select(selectTheme)
            .pipe(
                filter((val) => !!val),
                takeUntil(this._unsubscribe$)
            )
            .subscribe((theme) => {
                const html = this.externalWindow.document.querySelector('html');
                Object.values(Theme).forEach((item) => html.classList.remove(item));
                html.classList.add(theme);
            });
    }
}
