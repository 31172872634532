/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { SkipperService } from '../services/skipper.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private AUDIO_FILE_PATH = '/api/config-mgmt/v1/call-alerts/files/';
    private readonly mediaStreamAudioDestinationNode: MediaStreamAudioDestinationNode;
    public audioFileUrl: string;
    public readonly audioContext: AudioContext;
    public readonly mixerNode: GainNode;
    public readonly mediaStream: MediaStream;

    constructor(private skipperService: SkipperService) {
        this.skipperService.activeUrl$.subscribe((url) => this.audioFileUrl = `${url}${this.AUDIO_FILE_PATH}`);
        console.debug(`Creating alert audio context`);
        this.audioContext = new AudioContext();
        this.mixerNode = this.audioContext.createGain();
        this.mediaStreamAudioDestinationNode = this.audioContext.createMediaStreamDestination();
        this.mixerNode.connect(this.mediaStreamAudioDestinationNode);
        this.mediaStream = this.mediaStreamAudioDestinationNode.stream;
        console.debug(`Acquired alert media stream: ${this.mediaStream.id}`);
    }

    public checkContext() {
        if (this.audioContext.state === 'suspended') {
            this.audioContext
                .resume()
                .then(() => console.debug('Successfully started alert audio context'))
                .catch((err) => console.error('Unable to start alert audio context {}', err));
        }
    }
}
