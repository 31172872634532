/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { IAudioConfiguration, IAudioDeviceConnectionStatus, IUsbButtonConnectionStatus, IWebUsbConnectionStatus } from 'wam-wrapper/definitions';
import { IUsbButtonState } from 'wam-wrapper';
import { IDeviceEndpointBinder } from 'web-audio-module';

export const ccHubEffectsInitialized = createAction('[CCHub] Effects Initialized');

export const fetchWamConfig = createAction('[CCHub] Fetch WAM Config');
export const fetchWamConfigSuccess = createAction('[CCHub] Fetch WAM Config Success', props<{ config: IAudioConfiguration }>());
export const fetchWamConfigFailed = createAction('[CCHub] Fetch WAM Config Failed', props<{ payload: string }>());

export const ccHubSerialNumber = createAction('[CCHub] Serial Number', props<{ sn: string }>());

export const updateDeviceEndpointBinders = createAction(
    '[CCHub] Update Detected Device Endpoint Binders',
    props<{ endpointBinders: IDeviceEndpointBinder[] }>()
);

export const ccHubAttachedEvent = createAction('[CCHub] Attached Detection', props<{ isAttached: boolean }>());
export const ccWebUsbConnectionStatus = createAction('[CCHub] WebUsbConnectionStatus', props<{ event: IWebUsbConnectionStatus }>());
export const ccHubUsbButtonState = createAction('[CCHub] UsbButtonState Event', props<{ event: IUsbButtonState }>());
export const ccHubUsbButtonConnectionStatus = createAction('[CCHub] UsbButtonConnectionStatus Event', props<{ event: IUsbButtonConnectionStatus }>());
export const ccHubAudioDeviceConnectionEvent = createAction('[CCHub] AudioDeviceConnectionStatus Event', props<{ event: IAudioDeviceConnectionStatus }>());
export const headsetConnectedEvent = createAction('[CCHub] Headset Detection', props<{ isConnected: boolean }>());

export const ccHubInitialized = createAction('[CCHub] Initialized');
export const muteAction = createAction('[CCHub] Set Mute', props<{ peripheral: string; isMuted?: boolean }>());
export const muteOnAnswerUpdate = createAction('[CCHub] Auto Mute Update', props<{ peripheral: string; muteOnAnswer: boolean }>());
export const setPeripheralVolume = createAction('[CCHub] Peripheral Volume Update', props<{ peripheral: string; volume: number }>());

export const overrideCcHubHeadsetRequirement = createAction('[CCHub] Set Headset Override', props<{ required: boolean }>());

export const rpiDebugToggle = createAction('[CCHub] Set RPI Headset Debug Toggle', props<{ enabled: boolean }>());

export const alertToHeadsets = createAction('[CCHub] Headset Alerts To Headset Update', props<{ alert: boolean }>());
export const alertToDevice = createAction('[CCHub] Alert To Device Update', props<{ alert: boolean }>());

export const irrToHeadsets = createAction('[CCHub] Headset IRR To Headset Update', props<{ alert: boolean }>());
export const irrToDevice = createAction('[CCHub] IRR To Device Update', props<{ alert: boolean }>());

export const setFirmwareVersion = createAction('[CCHub] Set Firmware Version', props<{ value: string }>());
export const setFirmwareUpgrading = createAction('[CCHub] Upgrading Firmware', props<{ value: boolean }>());

export const ccHubInitiateUpgrade = createAction('[CCHub] Initiating Firmware Upgrade');
export const ccHubUpgradeSuccess = createAction('[CCHub] Firmware Upgrade Success');
export const ccHubUpgradeFailure = createAction('[CCHub] Firmware Upgrade Failed', props<{ payload: string }>());
