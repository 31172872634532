/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createFeatureSelector, createSelector, createSelectorFactory, DefaultProjectorFn, resultMemoize, select } from '@ngrx/store';
import { MediaState, selectAllMediaConnections, selectMediaConnectionEntities } from './media.reducer';
import { RxStompState } from '@stomp/rx-stomp';
import { ConnectionState } from '../../core/model/connectionState';
import { selectMemoizedUserAuthenticationRecord, selectPosition, selectPrimaryClusterName } from '../../user/+state/user.selectors';
import { selectClusterConfigurationByIdMap, selectCtcStatus, selectQuiescence } from '../../configuration/+state/configuration.selectors';
import { MediaConnection } from '../../core/model/media-connection';
import { MediaService } from '../services/media.service';
import {
    selectAlertOutputToDevice,
    selectCCHubAttached,
    selectCCHubConnectedAndInitialized,
    selectHeadsetConnected,
    selectIrrOutputToDevice
} from './cchub.selectors';
import { Cluster, ConnectionStatus } from 'CalltakingCoreApi';
import { CchubService } from '../services/cchub.service';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { selectAudioSettings } from '../../settings/+state/settings.selectors';

export const MEDIA_CONNECTIONS_FEATURE = 'media-connections';

export const selectMediaConnectionState = createFeatureSelector<MediaState>(MEDIA_CONNECTIONS_FEATURE);

export const selectMediaEffectInitialized = createSelector(selectMediaConnectionState, (state) => state.effectsInitialized);

export const selectMediaConnectionMap = createSelector(selectMediaConnectionState, selectMediaConnectionEntities);

export const selectMediaConnections = createSelector(selectMediaConnectionState, selectAllMediaConnections);

const _selectMediaConnection = (uuid: string) => createSelector(selectMediaConnectionMap,  (mediaConnectionMap) => mediaConnectionMap[uuid]);

export const selectMediaConnectionPassword = (uuid: string) => createSelector(_selectMediaConnection(uuid), (mediaConnection) => mediaConnection.configuration.password);

export const selectMediaConnectionIsConnected = (uuid: string) => createSelector(_selectMediaConnection(uuid), (mediaConnection) => Boolean(mediaConnection.connected));

export const selectInitializedMediaConnection = (uuid: string) => pipe(select(_selectMediaConnection(uuid)), filter((mediaConnection) =>
    Boolean(mediaConnection && mediaConnection.socket && mediaConnection.configuration?.uri && mediaConnection.configuration?.contact_uri && mediaConnection.configuration?.extra_headers?.length && mediaConnection.configuration?.password))
);

export const selectMediaConnectionsByClusterNameMap = createSelector(selectMediaConnections, (mediaConnections) =>
    mediaConnections.reduce<{ [key: string]: MediaConnection }>((map, mediaConnection) => ({ ...map, [mediaConnection.name]: mediaConnection }), {})
);

export const selectIsPhoneRegistered = createSelector(selectMediaConnections, (mediaConnections) =>
    mediaConnections.some((mediaConnection) => mediaConnection.connected && mediaConnection.registered)
);

export const selectIsPhoneFullyRegistered = createSelector(selectMediaConnections, (mediaConnections) =>
    mediaConnections.length && mediaConnections.every((mediaConnection) => mediaConnection.connected && mediaConnection.registered)
);

export const selectRegisteredMediaCount = createSelector(
    selectMediaConnections,
    (mediaConnections) => mediaConnections.filter((mediaConnection) => mediaConnection.connected && mediaConnection.registered).length
);

export const selectOrphanedSessions = createSelector(selectMediaConnections, (mediaConnections) =>
    mediaConnections
        .filter((mediaConnection) => (!mediaConnection.connected || !mediaConnection.registered) && mediaConnection.session)
        .map((deadMediaConnections) => deadMediaConnections.session)
        .filter((deadSession) => deadSession.state !== 'ENDED' && deadSession.state !== 'FAILED')
        .map((deadSession) => deadSession.id as string)
);

export const selectCallVolume = createSelector(
    selectMediaConnectionState,
    selectAudioSettings,
    (state, audioSettings) =>
        audioSettings && audioSettings.callOutputVolume || state.callVolume);

export const selectAlertVolume = createSelector(
    selectMediaConnectionState,
    selectAudioSettings,
    (state, audioSettings) =>
        audioSettings && audioSettings.alertOutputVolume || state.alertVolume);

export const selectIrrVolume = createSelector(
    selectMediaConnectionState,
    selectAudioSettings,
    (state, audioSettings) => audioSettings && audioSettings.irrOutputVolume || state.irrVolume);

export const selectHasUserMedia = createSelector(selectMediaConnectionState, (state) => state.hasUserMedia);

export const selectUserMediaDevices = createSelector(selectMediaConnectionState, (state) => state.userMediaDevices);

export const selectUserAudioInputMediaDevices = createSelector(
    selectMediaConnectionState,
    (state) => state.userMediaDevices?.filter((md) => md.kind === 'audioinput' && !md.label.includes(CchubService.CCH_ID))
);

export const selectUserAudioOutputMediaDevices = createSelector(
    selectMediaConnectionState,
    (state) => state.userMediaDevices?.filter((md) => md.kind === 'audiooutput' && !md.label.includes(CchubService.CCH_ID))
);

export const selectUserMediaInputDeviceId = createSelector(
    selectMediaConnectionState,
    selectAudioSettings,
    selectUserAudioInputMediaDevices,
    (state, audioSettings, allInputDevices) =>
       allInputDevices.map((d) => d.deviceId).includes(audioSettings?.mainInputDeviceId) ? audioSettings.mainInputDeviceId : state.userMediaInputDeviceId);

export const selectUserMediaInputDevice = createSelector(
    selectUserAudioInputMediaDevices,
    selectUserMediaInputDeviceId,
    selectCCHubAttached,
    (mediaDevices, deviceId, ccHubAttached) => ccHubAttached ? undefined : mediaDevices?.find((md) => md.deviceId === deviceId)
);

export const selectUserMediaOutputDeviceId = createSelector(
    selectMediaConnectionState,
    selectAudioSettings,
    selectUserAudioOutputMediaDevices,
    (state, audioSettings, allOutputDevices) =>
        allOutputDevices.map((d) => d.deviceId).includes(audioSettings.mainOutputDeviceId) ? audioSettings.mainOutputDeviceId : state.userMediaOutputDeviceId);

export const selectAlertMediaOutputDeviceId = createSelector(
    selectMediaConnectionState,
    selectAudioSettings,
    selectUserAudioOutputMediaDevices,
    (state, audioSettings, allOutputDevices) =>
        allOutputDevices.map((d) => d.deviceId).includes(audioSettings.alertOutputDeviceId) ? audioSettings.alertOutputDeviceId : state.audibleAlertOutputDeviceId);

export const selectIrrMediaOutputDeviceId = createSelector(
    selectMediaConnectionState,
    selectAudioSettings,
    selectUserAudioOutputMediaDevices,
    (state, audioSettings, allOutputDevices) =>
        allOutputDevices.map((d) => d.deviceId).includes(audioSettings.irrOutputDeviceId) ? audioSettings.irrOutputDeviceId : state.irrOutputDeviceId);

export const selectUserMediaOutputDevice = createSelector(
    selectUserAudioOutputMediaDevices,
    selectUserMediaOutputDeviceId,
    selectCCHubAttached,
    (mediaDevices, deviceId, ccHubAttached) => ccHubAttached ? undefined : mediaDevices?.find((md) => md.deviceId === deviceId)
);

export const selectAlertMediaOutputDevice = createSelector(
    selectUserAudioOutputMediaDevices,
    selectAlertMediaOutputDeviceId,
    selectCCHubAttached,
    selectAlertOutputToDevice,
    (mediaDevices, deviceId, ccHubAttached, alertOutputToDevice) =>
        ccHubAttached && !alertOutputToDevice ? undefined : mediaDevices?.find((md) => md.deviceId === deviceId)
);

export const selectIrrOutputDevice = createSelector(
  selectUserAudioOutputMediaDevices,
  selectIrrMediaOutputDeviceId,
    selectCCHubAttached,
    selectIrrOutputToDevice,
  (mediaDevices, deviceId, ccHubAttached, irrOutputToDevice) =>
      ccHubAttached && !irrOutputToDevice ? undefined : mediaDevices?.find((md) => md.deviceId === deviceId)
);

export const selectSipDebugEnabled = createSelector(selectMediaConnectionState, (state) => state.sipDebugEnable);

export const selectWebsocketConnectionState = createSelector(selectMediaConnectionState, (state) => state.webSocketConnection);

export const selectHasWebsocketConnection = createSelector(selectWebsocketConnectionState, (state) => Boolean(state === RxStompState.OPEN));


export function ctcAvailabilityIsequal(a: { clusterName: string, available: boolean }, b: { clusterName: string, available: boolean }): boolean {
    return a.available === b.available && a.clusterName === b.clusterName;
}

export const ctcAvailabilityMemoize = (projectorFn: DefaultProjectorFn<{ clusterName: string, available: boolean }>) => resultMemoize(projectorFn, ctcAvailabilityIsequal);

export const selectCtcAvailability = (uuid: string) => createSelectorFactory(ctcAvailabilityMemoize)(selectCtcStatus, selectClusterConfigurationByIdMap,  (status: {[key: string]: boolean}, clusterMap: {[p: string]: Cluster}) =>
    ({ clusterName: clusterMap[uuid]?.name, available: Boolean(status[clusterMap[uuid].name]) }));

export const selectClusterConnectionStatus = (uuid: string) => createSelector(selectHasWebsocketConnection, selectCtcAvailability(uuid), selectMediaConnectionIsConnected(uuid), (ctcWebsocket, { available }, astWebsocket) =>
    ({ http: available, ctcWebsocket: ctcWebsocket, astWebsocket: astWebsocket }));

export const selectPreferredOutboundCallClusterName = createSelector(selectPrimaryClusterName, selectMediaConnectionsByClusterNameMap, selectQuiescence,
    (homeCluster, mediaConnectionsMap, quiescence) => !quiescence[homeCluster].quiesced && mediaConnectionsMap[homeCluster].registered ? homeCluster : Object.values(mediaConnectionsMap).filter((mediaConnection) => !quiescence[mediaConnection.name]?.quiesced).find((connection) => connection.registered)?.name
);

export const selectConnectionStatus = createSelector(
    selectPosition,
    selectHasUserMedia,
    selectHasWebsocketConnection,
    selectIsPhoneRegistered,
    selectIsPhoneFullyRegistered,
    selectCCHubAttached,
    selectCCHubConnectedAndInitialized,
    selectHeadsetConnected,
    (hasPosition, hasMic, hasWebsocket, hasPhone, hasAllPhones, ccHubAttached, ccHubConnectedAndInitialized, ccHubHeadsetConnected) =>
        hasPosition && hasMic && hasWebsocket && hasAllPhones && (ccHubAttached && ccHubConnectedAndInitialized && ccHubHeadsetConnected || !ccHubAttached)
            ? ConnectionState.FULL
            : hasPosition && hasMic && hasWebsocket && hasPhone && (ccHubAttached && ccHubConnectedAndInitialized && ccHubHeadsetConnected || !ccHubAttached)
            ? ConnectionState.PARTIAL
            : ConnectionState.DOWN
);

export const selectMediaConnectionStatus = createSelector(selectMemoizedUserAuthenticationRecord, selectMediaConnections, (user, mediaConnections) =>
    user ? mediaConnections.reduce<{ [key: string]: ConnectionStatus }>((map, mediaConnection) => ({ ...map, [mediaConnection.asteriskAddress]: MediaService.getConnectionStatus(mediaConnection) }), {}) : undefined);

export const selectIsNetworkDisconnected = createSelector(selectConnectionStatus, (status) => status === ConnectionState.DOWN);

