/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[msiFocusFirst]'
})
export class FocusFirstDirective implements AfterViewInit{

    @Input() focusInputKeys: string[] = ["input","textarea"];

    @Input()
    set focusKey(_: number | string) {
        // This is our key to detect when to re-check the dom and focus
        this._setFocus();
    }

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        // Runs a check after dom is finished rendering (for ng-containers, etc)
        this._setFocus();
    }

    private _setFocus() {
        const element = this.el.nativeElement as HTMLElement;
        const input = element.querySelectorAll(this.focusInputKeys.join(','))[0] as HTMLInputElement;
        if (input) {
            setTimeout(()=> {
                input.focus();
            });
        }
    }

}
