import { Pipe, PipeTransform } from '@angular/core';
import { CallTableData } from '../../supervisor/models/call-table-data';
import { UserAuthenticationRecord } from 'CalltakingCoreApi';

@Pipe({
    name: 'filteredCalls'
})
export class FilteredCallsPipe implements PipeTransform {
    transform<T extends CallTableData>(calls: T[], filter: string, userMap: Record<string, UserAuthenticationRecord>, callbackMap?: Record<string, string>) {
        if (filter && filter.length > 0) {
            const normalizedFilter = filter.toLowerCase().replace(',', '');
            return calls.filter((a) => {

                const callback = callbackMap && callbackMap[a.uuid] ? callbackMap[a.uuid] : a.callback;

                return (
                    (callback || '').toLowerCase().includes(normalizedFilter) ||
                    Object.values(a.participants).some((p) => this.matchUser(userMap[p.name], normalizedFilter))
                );
            });
        }
        return calls as T[];
    }

    matchUser(user: UserAuthenticationRecord, normalizedFilter: string) {
       if (!user) return false;
       const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
       return fullName.includes(normalizedFilter);
    }
}
