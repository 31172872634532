/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { clearToastNotification, displayToastNotification } from './notification.actions';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../core/services/notification.service';

@Injectable({ providedIn: 'root' })
export class NotificationEffects {
    constructor(
        private actions$: Actions,
        private notificationService: NotificationService
    ) {}

    displayNotification$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(displayToastNotification),
                tap(({ message, level, options }) => this.notificationService.showToast(message, level, options))
            );
        },
        { dispatch: false }
    );

    clearNotification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clearToastNotification),
            tap(({ ref }) => this.notificationService.dismissToast(ref))
        );
    }, { dispatch: false });
}
