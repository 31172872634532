/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import { AgentStatus } from "CalltakingCoreApi";

@Pipe({
    name: 'acdStatusLabel'
})
export class AcdStatusLabelPipe implements PipeTransform {

    transform(value: AgentStatus, hideReady = false): string {
        switch (value) {
            case "READY":
            case "ASSIGNED_CALL":
            case "ASSIGNED_ABANDONED_CALL":
                return hideReady ? '' : 'Ready';
            case "ON_CALL":
            case "ON_ACD_CALL":
            case "HOLDING_CALL":
            case "WRAPUP":
                return 'Busy';
            case "NOT_READY":
            case "PHONE_UNAVAILABLE":
            case "UNKNOWN":
            default:
                return 'Not Ready';
        }
    }

}
