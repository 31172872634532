import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memoizedTemplateFunction'
})
export class MemoizedTemplateFunctionPipe implements PipeTransform {

    public transform(templateValue: any, fnReference: Function, ...fnArguments: any[]) : any {
        // adds the template value triggering the pipe as the first argument
        fnArguments.unshift(templateValue);
        // note that we don't have context so invokers will need to use arrow notation on function declaration
        return fnReference.apply(null, fnArguments);
    }

}
