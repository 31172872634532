import { Pipe, PipeTransform } from '@angular/core';
import {SortFunction} from "../model/sortable";

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform<T>(array: T[], sort: SortFunction<T>): T[] {
        return [...array].sort(sort);
    }
}
