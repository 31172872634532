/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';

import { CCHSwdlManager } from 'web-usb-swdl';
import { MessageInfo, MessageError } from 'web-usb-common';
import { Diagnostics } from 'wam-wrapper';
import { HttpClient } from "@angular/common/http";
import { setFirmwareUpgrading, setFirmwareVersion } from "../+state/cchub.actions";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class WebUsbSwdlService {
  private static diagnostics: Diagnostics = Diagnostics.getInstance();
  private webUsbSwdlManager: CCHSwdlManager;
  private deviceDataSubject: Subject<string>;
  private deviceErrorSubject: Subject<[string, string]>;
  private swdlCounterSubject: Subject<[number, number]>;
  private startedSwdlCounter = 0;
  private sucesssfulSwdlCounter = 0;
  private deviceDataSubscription: Subscription;
  private deviceErrorSubscription: Subscription;
  private swdlCounterSubscription: Subscription;

  constructor(private http: HttpClient, public store: Store) {
    this.webUsbSwdlManager = new CCHSwdlManager(WebUsbSwdlService.diagnostics);
    this.deviceDataSubject = new Subject<string>();
    this.deviceErrorSubject = new Subject<[string, string]>();
    this.swdlCounterSubject = new Subject<[number, number]>();
  }

  async connect() {
      await this.webUsbSwdlManager.connect();
  }

  async close() {
      await this.webUsbSwdlManager.stop();
  }

  async init() {
    await this.webUsbSwdlManager.start((info: MessageInfo) => {
      this.deviceDataSubject.next(info.message);
      if (info.message === 'SWDL finished.') {
        this.sucesssfulSwdlCounter++;
        this.swdlCounterSubject.next([this.startedSwdlCounter, this.sucesssfulSwdlCounter]);
      }
    },
      (versionInfo: {
        activeBank: number,
        defaultBankFirmwareVersion: string,
        alternateBankFirmwareVersion: string;
      }) => {
        this.deviceDataSubject.next('active bank: ' + versionInfo.activeBank
          + ', defaultBankFirmwareVersion: ' + versionInfo.defaultBankFirmwareVersion
          + ', alternateBankFirmwareVersion: ' + versionInfo.alternateBankFirmwareVersion);
        if (versionInfo.activeBank == 0) {
            this.store.dispatch(setFirmwareVersion({ value: versionInfo.defaultBankFirmwareVersion }));
        } else {
            this.store.dispatch(setFirmwareVersion({ value: versionInfo.alternateBankFirmwareVersion }));
        }
      },
      (error: MessageError) => {
        if (error.message === 'No firmware provided') {
          this.startedSwdlCounter--;
          this.swdlCounterSubject.next([this.startedSwdlCounter, this.sucesssfulSwdlCounter]);
        }
        this.deviceErrorSubject.next([error.name, error.message]);
      }
    );
    this.deviceDataSubscription = this.deviceDataSubject.subscribe(
      info => {
          console.log(info)
      }
    );
    this.deviceErrorSubscription = this.deviceErrorSubject.subscribe(
      info => {
          console.error(info)
      }
    );
    this.swdlCounterSubscription = this.swdlCounterSubject.subscribe(
      info => {
          console.info('Number of FW flash attempts: ' + info[0]);
          console.info('Number of successful FW loads: ' + info[1]);
      }
    );
    console.log('Connecting to CC-Hub');
    await this.connect();
  }

  public upgrade() {
      console.log('Downloading firmware...')
      this.store.dispatch(setFirmwareUpgrading({ value: true }));
      this.http.get('assets/firmware/cchub_firmware_1.1.0.5.bin', {responseType: 'arraybuffer'})
          .subscribe((data) => {
              this.startedSwdlCounter++;
              this.swdlCounterSubject.next([this.startedSwdlCounter, this.sucesssfulSwdlCounter]);
              console.log('Applying firmware...');
              this.webUsbSwdlManager.startSWDL(data)
                  .then(() => {
                      this.store.dispatch(setFirmwareUpgrading({ value: false }));
                      window.location.reload();
                  })
                  .catch((e) => {
                      this.store.dispatch(setFirmwareUpgrading({ value: false }));
                      console.error('Unable to apply firmware', e);
                  });
          });
      return of(true);
  }
}
