/*
 * *****************************************************************************
 *     Copyright (C) 2024 Motorola Solutions, Inc.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import { AnalyticsHistoricalCall } from '../../call/model/analytics-historical-call';

@Pipe({
    name: 'callIconHistorical'
})
export class CallIconHistoricalPipe implements PipeTransform {
    transform(mediaType: AnalyticsHistoricalCall['mediaType']): string {
        switch (mediaType) {
            case 'RTT':
                return 'ic_call_rtt';
            case 'TDD':
                return 'ic_call_rtt_tty';
            case 'SMS':
                return 'ic_conversation';
            default:
                return 'ic_call';
        }
    }
}
