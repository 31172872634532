import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayIncludes'
})
export class ArrayIncludesPipe implements PipeTransform {
    transform<T>(array: T[], findMe: T | T[], negate = false): boolean {
        let result;

        if (!array) {
            return false;
        }

        if (Array.isArray(findMe)) {
            result = findMe.every((search: any) => array.includes(search));
        } else {
            result = array.includes(findMe);
        }

        return negate ? !result : result;
    }
}
