/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {Pipe, PipeTransform} from "@angular/core";
import {
    TimeHelper, TimeHelperFormat
} from "../../core/util/time-helper";

@Pipe({
    name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {
    transform(seconds: number | string, format?: TimeHelperFormat) {
        return TimeHelper.convertSecondsToTime(+seconds, format);
    }

}
