/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { InputDeviceInterface } from './input-device-interface';

export class SingleChannelInputDeviceInterface extends InputDeviceInterface {
    protected readonly channelSplitterNode: ChannelSplitterNode;

    constructor(mediaDeviceInfo: MediaDeviceInfo, channel: number) {
        super(mediaDeviceInfo, channel);
        this.channelSplitterNode = this.audioContext.createChannelSplitter();
    }

    protected connect() {
        this.mediaStreamAudioSourceNode.connect(this.channelSplitterNode);
        this.channelSplitterNode.connect(this.mixerNode, this.channel - 1, 0);
    }

    public destroy() {
        this.mediaStreamAudioSourceNode?.disconnect();
        this.channelSplitterNode.disconnect();
        this.mixerNode.disconnect();
        this.audioContext
            .close()
            .then(() => console.debug(`Destroyed audio context associated with ${this.device.label}`))
            .catch(() => console.error(`Failed to destroy audio context associated with ${this.device.label}`));
    }
}
