/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionGuardService {

    private readonly broadcastChannel= new BroadcastChannel("MSI_CALL_HANDLING_INSTANCE_CHECK");
    private resolveAfter = (result: any, delay: number) => new Promise((resolve) => setTimeout(() => resolve(result), delay));

    constructor() {
        this.broadcastChannel.addEventListener('message', () => this.broadcastChannel.postMessage(true));
    }

    public canInitialize() {
        let response = new Promise((resolve, reject) =>
            this.broadcastChannel.addEventListener('message', () => reject('Duplicate client instances detected.')));
        let result = Promise.race([response, this.resolveAfter(true, 500)]);
        this.broadcastChannel.postMessage(true);
        return result;
    }
}
