/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
    transform(
        value: string,
        strToReplace: string,
        replacementStr: string
    ): string {
        if (!value || !strToReplace || !replacementStr) {
            return value;
        }
        return value.replace(
            new RegExp(escapeRegex(strToReplace), 'g'),
            replacementStr
        );
    }
}

function escapeRegex(string: string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
