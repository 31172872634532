/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Call, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { SortFunctions } from '../util/sort-functions';
import { CallFunctions } from '../util/call-functions';

@Pipe({
    name: 'participantList'
})
export class ParticipantListPipe implements PipeTransform {
    transform(call: Call, userMap: Record<string, UserAuthenticationRecord>): string {
        const participants = Object.values(call.participants).filter(
            (p) => !p.silentMonitor && (!p.leftOn || CallFunctions.isHeldParticipant(call, p.username))
        );

        if (participants.length > 1) {
            return participants
                .sort(SortFunctions.joinedOnSort)
                .map((p) => userMap[p.name]?.lastName ?? p.alias ?? p.name)
                .join('; ');
        } else if (participants.length) {
            const agent = userMap[participants[0].name];
            return agent?.firstName ? agent?.lastName + ', ' + agent?.firstName : participants[0].alias ?? participants[0].name;
        }

        return '--';
    }
}
