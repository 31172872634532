/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { SingleChannelOutputDeviceInterface } from './single-channel-output-device-interface';
import { DeviceEndpointBinder } from 'web-audio-module/dist/peripheral-configuration-tool/i-deviceendpoint-binder';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectAlertOutputEnabled, selectCcHubAudioOutputConnected, selectCCHubPeripheralVolume, selectIrrOutputEnabled } from '../+state/cchub.selectors';
import { selectCcHubOutputOnCall } from '../+state/call.selectors';

export class CcHubOutputDeviceInterface extends SingleChannelOutputDeviceInterface {
    private readonly peripheral: string;
    private volumeSubscription: Subscription;
    private connectedSubscription: Subscription;
    private onCallSubscription: Subscription;
    private alertToHeadsetsSubscription: Subscription;
    private irrToHeadsetsSubscription: Subscription;
    private _onCall: boolean = false;
    private _connected: boolean = false;
    private _alertsEnabled: boolean = false;
    private _irrEnabled: boolean = false;
    private store: Store;
    protected sharedMicLocalMediaSourceNodes: MediaStreamAudioSourceNode[] = [];
    protected alertMediaSourceNode: MediaStreamAudioSourceNode;
    protected alertMediaStream: MediaStream;
    protected irrMediaSourceNode: MediaStreamAudioSourceNode;
    protected irrMediaStream: MediaStream;

    constructor(peripheral: DeviceEndpointBinder, store: Store) {
        super(peripheral.deviceendpoint, peripheral.deviceendpoint.channel);
        this.peripheral = peripheral.peripheral;
        this.store = store;
    }

    init(): Promise<void> {
        return super.init().then(() => {
            this.connectedSubscription = this.store.select(selectCcHubAudioOutputConnected(this.peripheral)).subscribe((connected) => this.connected = connected);
            this.onCallSubscription = this.store.select(selectCcHubOutputOnCall(this.peripheral)).subscribe((onCall) => this.onCall = onCall);
            this.volumeSubscription = this.store.select(selectCCHubPeripheralVolume(this.peripheral)).subscribe((volume) => this.setAudioVolume(volume));
            this.alertToHeadsetsSubscription = this.store.select(selectAlertOutputEnabled(this.peripheral)).subscribe((alertToHeadsets) => this.alertsEnabled = alertToHeadsets);
            this.irrToHeadsetsSubscription = this.store.select(selectIrrOutputEnabled(this.peripheral)).subscribe((irrToHeadsets) => this.irrEnabled = irrToHeadsets);
        });
    }

    destroy() {
        super.destroy();
        this.connectedSubscription?.unsubscribe();
        this.onCallSubscription?.unsubscribe();
        this.volumeSubscription?.unsubscribe();
        this.alertToHeadsetsSubscription?.unsubscribe();
        this.irrToHeadsetsSubscription?.unsubscribe();
    }

    get connected() {
        return this._connected;
    }

    set connected(isConnected: boolean) {
        this._connected = isConnected;
        this.connected ? this.enable() : this.disable();
    }

    public enable() {
        console.debug(`Enabling output to ${this.peripheral}`);
        this.channelMergerNode.connect(this.audioContext.destination);
    }

    public disable() {
        console.debug(`Disabling output to ${this.peripheral}`);
        this.channelMergerNode.disconnect(this.audioContext.destination);
    }

    get onCall() {
        return this._onCall;
    }

    set onCall(onCall: boolean) {
        this._onCall = onCall;
        this.onCall ? this.enableMicSharing() : this.disableMicSharing();
    }

    public enableMicSharing() {
        console.debug(`Enabling shared mic output to ${this.peripheral}`);
        this.sharedMicLocalMediaSourceNodes.forEach((source) => {
            console.debug(`Attaching local media stream ${source.mediaStream.id} to ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            source.connect(this.mixerNode);
        });
    }

    public disableMicSharing() {
        console.debug(`Disabling shared mic output to ${this.peripheral}`);
        this.sharedMicLocalMediaSourceNodes.forEach((source) => {
            console.debug(`Removing local media stream ${source.mediaStream.id} from ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            source.disconnect(this.mixerNode);
        });
    }

    public setSharedLocalMicrophoneMediaStreams(mediaStreams: MediaStream[]) {
        let nodesToRemove = this.sharedMicLocalMediaSourceNodes.filter((node) => !mediaStreams.map((mediaStream) => mediaStream.id).includes(node.mediaStream.id));
        let streamsToAdd = mediaStreams.filter((mediaStream) => !this.sharedMicLocalMediaSourceNodes.map((node) => node.mediaStream.id).includes(mediaStream.id));

        nodesToRemove.forEach((source) => {
            console.debug(`Removing shared microphone stream ${source.mediaStream.id} from ${this.peripheral}`);
            source.disconnect(this.mixerNode);
        });
        this.sharedMicLocalMediaSourceNodes = this.sharedMicLocalMediaSourceNodes.filter((node) => !nodesToRemove.includes(node));

        let nodesToAdd = streamsToAdd.map((mediaStream) => this.audioContext.createMediaStreamSource(mediaStream));
        nodesToAdd.filter(() => this._onCall).forEach((source) => {
            console.debug(`Attaching shared microphone stream ${source.mediaStream.id} to ${this.peripheral}`);
            source.connect(this.mixerNode);
        });
        this.sharedMicLocalMediaSourceNodes.push(...nodesToAdd);
    }

    get alertsEnabled() {
        return this._alertsEnabled;
    }

    set alertsEnabled(alertEnabled: boolean) {
        this._alertsEnabled = alertEnabled;
        if (this.alertMediaSourceNode) {
            console.debug(`Alerts disabled on ${this.peripheral}`);
            this.alertMediaSourceNode?.disconnect(this.mixerNode);
            this.alertMediaSourceNode = undefined;
        }
        if (this.alertsEnabled && this.alertMediaStream) {
            console.debug(`Alerts enabled on ${this.peripheral}`);
            this.alertMediaSourceNode = this.audioContext.createMediaStreamSource(this.alertMediaStream);
            this.alertMediaSourceNode.connect(this.mixerNode);
        }
    }

    public setAlertMediaStream(mediaStream: MediaStream) {
        this.alertMediaStream = mediaStream;
        this.alertsEnabled = this._alertsEnabled;
    }


    get irrEnabled() {
        return this._irrEnabled;
    }

    set irrEnabled(irrEnabled: boolean) {
        this._irrEnabled = irrEnabled;
        if (this.irrMediaSourceNode) {
            console.debug(`IRR playback disabled on ${this.peripheral}`);
            this.irrMediaSourceNode?.disconnect(this.mixerNode);
            this.irrMediaSourceNode = undefined;
        }
        if (this.irrEnabled && this.irrMediaStream) {
            console.debug(`IRR playback enabled on ${this.peripheral}`);
            this.irrMediaSourceNode = this.audioContext.createMediaStreamSource(this.irrMediaStream);
            this.irrMediaSourceNode.connect(this.mixerNode);
        }
    }

    public setIrrMediaStream(mediaStream: MediaStream) {
        this.irrMediaStream = mediaStream;
        this.irrEnabled = this._irrEnabled;
    }

}
