/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

export enum ConnectionState {
    FULL = 'FULL',
    PARTIAL = 'PARTIAL',
    DOWN = 'DOWN'
}
