import { Component, Input } from '@angular/core';
import { UserAuthenticationRecord } from "CalltakingCoreApi";

@Component({
  selector: 'msi-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent  {

  @Input()
  public agent: UserAuthenticationRecord | undefined;

  @Input()
  public showPosition!: boolean;

  constructor() { }

}
