/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastType } from '@msi/cobalt';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { displayToastNotification } from '../notification/+state/notification.actions';
import { EnvironmentService } from '../core/services/environment.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorToastInterceptor implements HttpInterceptor {
    private static readonly STATUS_CODE_EXCLUSIONS: HttpStatusCode[] = [
        HttpStatusCode.BadGateway,
        HttpStatusCode.GatewayTimeout,
        HttpStatusCode.ServiceUnavailable,
        HttpStatusCode.InternalServerError,
        HttpStatusCode.NotFound,
        HttpStatusCode.Unauthorized
    ];

    constructor(private store: Store, private environmentService: EnvironmentService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((err) => this.handleError(err)));
    }

    private handleError(error: HttpErrorResponse) {
        console.error('Error on request', error);
        if (
            !ErrorToastInterceptor.STATUS_CODE_EXCLUSIONS.some((exclusion) => error.status === exclusion) &&
            !this.environmentService.environment?.featureFlags?.hideErrorNotifications
        ) {
            this.store.dispatch(
                displayToastNotification({
                    message: this.getErrorMessage(error),
                    level: ToastType.error
                })
            );
        }

        return throwError(() => error);
    }

    getErrorMessage(error: HttpErrorResponse) {
        return error.error && !error.error.includes('</html>')
            ? error.error
            : error.message && !error.message.includes('</html>')
            ? error.message
            : `${error.status} ${error.statusText} ${error.url}`;
    }
}
