/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import * as dayjs from 'dayjs';

export type TimeHelperFormat = "mm:ss" | "m:ss" | "HH:mm:ss";

export class TimeHelper {
    public static convertSecondsToTime(s: number, format:TimeHelperFormat = "HH:mm:ss") {
        if (s === null || s === undefined) {
            return '--';
        }
        return dayjs.duration(s, 'seconds').format(format);
    }

}
