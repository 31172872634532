/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCcHubCallAudioInputs, selectCcHubCallAudioOutputs } from '../+state/cchub.selectors';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject, map } from 'rxjs';
import { MediaService } from './media.service';
import { AudioDevicesRoles, AudioDevicesRolesInternal } from 'wam-wrapper';
import { CcHubOutputDeviceInterface } from '../model/cc-hub-output-device-interface';
import { CcHubInputDeviceInterface } from '../model/cc-hub-input-device-interface';
import { getUserMediaSuccess } from '../+state/media.actions';
import { AlertService } from '../../core/audio/alert.service';
import { IrrService } from '../../core/audio/irr.service';

@Injectable({
    providedIn: 'root'
})
export class CcHubAudioService {
    private readonly mixedLocalMediaContext = new AudioContext();
    private readonly localMediaMixerNode = new GainNode(this.mixedLocalMediaContext);
    private readonly mixedLocalDestinationNode = new MediaStreamAudioDestinationNode(this.mixedLocalMediaContext);
    private localMediaSourceNodes: MediaStreamAudioSourceNode[] = [];

    // Used to filter inputs from its own output (ie, don't play a headset mic input audio back into its own headset output)
    static readonly INPUT_FILTER_MAP: { [key: string]: string } = {
        [AudioDevicesRoles.HEADSET_1_MIC]: AudioDevicesRoles.HEADSET_1_PHONE_SPEAKER,
        [AudioDevicesRoles.HEADSET_2_MIC]: AudioDevicesRoles.HEADSET_2_PHONE_SPEAKER,
        [AudioDevicesRolesInternal.RPI_IN]: AudioDevicesRolesInternal.RPI_OUT
    };

    private inputDeviceInterfaceMap: { [peripheral: string]: CcHubInputDeviceInterface } = {};
    private outputDeviceInterfaceMap: { [peripheral: string]: CcHubOutputDeviceInterface } = {};

    private localMediaStreamsMap$ = new BehaviorSubject<{ [peripheral: string]: MediaStream }>({});

    constructor(
        private store: Store,
        private mediaService: MediaService,
        private alertService: AlertService,
        private irrService: IrrService
    ) {}

    public init() {
        this.localMediaMixerNode.connect(this.mixedLocalDestinationNode);
        this.mediaService.setLocalMediaStream(this.mixedLocalDestinationNode.stream);
        this.checkContext();
        this.monitorCcHubInputs();
        this.monitorCcHubOutputs();
        this.monitorMediaStreams();
    }

    /**
     *  Discover and initialize all CCHub input devices
     *  **/
    private monitorCcHubInputs() {
        this.store
            .select(selectCcHubCallAudioInputs)
            .pipe(debounceTime(200))
            .subscribe((inputs) => {
                //initialize new inputs devices
                let newInputDevices = inputs.filter((inputDeviceBinders) => !Object.keys(this.inputDeviceInterfaceMap).includes(inputDeviceBinders.peripheral));
                newInputDevices.forEach(
                    (inputDeviceBinder) =>
                        (this.inputDeviceInterfaceMap[inputDeviceBinder.peripheral] = new CcHubInputDeviceInterface(inputDeviceBinder, this.store))
                );
                // note the promise still resolves if there are no new input devices.
                Promise.all(newInputDevices.map((device) => this.inputDeviceInterfaceMap[device.peripheral].init()))
                    .then(() => this.store.dispatch(getUserMediaSuccess()))
                    .then(() => this.localMediaStreamsMap$.next(
                        Object.entries(this.inputDeviceInterfaceMap).reduce<{ [peripheral: string]: MediaStream }>(
                            (map, [peripheral, inputInterface]) => ({ ...map, [peripheral]: inputInterface.mediaStream }),
                            {}
                        )
                    ));
            });
        this.localMediaStreamsMap$
            .pipe(debounceTime(200), map((localMediaStreamsMap) => Object.values(localMediaStreamsMap)))
            .subscribe((localMediaStreams) => this.updateMixedLocalMediaStream(localMediaStreams));
    }

    /**
     *  Discover and initialize all CCHub output devices
     *  **/
    private monitorCcHubOutputs() {
        this.store
            .select(selectCcHubCallAudioOutputs)
            .pipe(debounceTime(200))
            .subscribe((outputs) => {
                // add new devices
                outputs
                    .filter((outputDeviceBinder) => !Object.keys(this.outputDeviceInterfaceMap).includes(outputDeviceBinder.peripheral))
                    .forEach((outputDeviceBinder) => {
                        this.outputDeviceInterfaceMap[outputDeviceBinder.peripheral] = new CcHubOutputDeviceInterface(outputDeviceBinder, this.store);
                        this.outputDeviceInterfaceMap[outputDeviceBinder.peripheral].init().then(() => {
                            this.outputDeviceInterfaceMap[outputDeviceBinder.peripheral].setRemoteMediaStream(this.mediaService.remoteMediaStream$.value);
                            this.outputDeviceInterfaceMap[outputDeviceBinder.peripheral].setSharedLocalMicrophoneMediaStreams(
                                this.getSharedLocalMediaStreams(outputDeviceBinder.peripheral, this.localMediaStreamsMap$.value)
                            );
                            this.outputDeviceInterfaceMap[outputDeviceBinder.peripheral].setAlertMediaStream(this.alertService.mediaStream);
                            this.outputDeviceInterfaceMap[outputDeviceBinder.peripheral].setIrrMediaStream(this.irrService.mediaStream);
                        });
                    });
            });
    }

    public updateMixedLocalMediaStream(localMediaStreams: MediaStream[]) {
        this.localMediaSourceNodes.forEach((node) => node.disconnect());
        this.localMediaSourceNodes = localMediaStreams.map((mediaStream) => new MediaStreamAudioSourceNode(this.mixedLocalMediaContext, { mediaStream }));
        this.localMediaSourceNodes.forEach((node) => node.connect(this.localMediaMixerNode));
    }

    private monitorMediaStreams() {
        this.mediaService.remoteMediaStream$.subscribe((remoteMediaStream) =>
            Object.values(this.outputDeviceInterfaceMap).forEach((device) => device.setRemoteMediaStream(remoteMediaStream))
        );
        this.localMediaStreamsMap$.subscribe((localMediaStreamMap) =>
            Object.entries(this.outputDeviceInterfaceMap).forEach(([outputPeripheral, outputDeviceInterface]) =>
                outputDeviceInterface.setSharedLocalMicrophoneMediaStreams(this.getSharedLocalMediaStreams(outputPeripheral, localMediaStreamMap))
            )
        );
    }

    private getSharedLocalMediaStreams(outputDevice: string, localMediaStreamMap: { [peripheral: string]: MediaStream }) {
        return Object.entries(localMediaStreamMap)
            .filter(([inputDevice,]) => CcHubAudioService.INPUT_FILTER_MAP[inputDevice] !== outputDevice)
            .map(([, mediaStream]) => mediaStream);
    }

    public checkContext() {
        console.debug(`Checking cc-hub combined input audio context: ${this.mixedLocalMediaContext.state}`);
        if (this.mixedLocalMediaContext.state === 'suspended') {
            console.warn(`Cc-hub combined input audio context is suspended. Attempting to resume.`);
            this.mixedLocalMediaContext
                .resume()
                .then(() => console.warn(`Successfully started cc-hub combined input audio context`))
                .catch((err) => console.error(`Unable to start cc-hub combined input audio context {}`, err));
        }
    }
}
