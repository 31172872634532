/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'callStatus'
})
export class CallStatusPipe implements PipeTransform {
    transform(value: string, isActiveParticipant: boolean): string {
        switch (value) {
            case 'RINGING':
                return 'Ringing';
            case 'CONNECTED':
                return isActiveParticipant ? 'Connected' : 'Active';
            case 'ON_HOLD':
                return 'On Hold';
            case 'ABANDONED':
            case 'ABANDONED_REDIAL':
            case 'ABANDONED_INVALID_REDIAL':
            case 'ABANDONED_REDIALING':
                return 'Abandoned';
            case 'TRANSFERRED':
                return 'Transferred';
            case 'RELEASED':
            case 'ABANDONED_CLEARED':
                return 'Released';
            case 'HISTORICAL':
                return 'Historical';
            default:
                return 'Unknown';
        }
    }
}
