/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { FilterMenuCategory } from '../../supervisor/models/checkbox-object';
import { agentFilter, applicationFilter, notificationFilter } from './settings.model';

export const notificationFilters: FilterMenuCategory<notificationFilter>[] = [
    {
        name: 'Notification categories',
        filters: [
            { displayName: 'Announcements', key: 'announcements' },
            { displayName: 'System', key: 'system' }
        ]
    }
];

export const agentTableFilters: FilterMenuCategory<agentFilter>[] = [
    {
        name: 'Agent status',
        filters: [
            { displayName: 'Not ready', key: 'NOT_READY' },
            { displayName: 'Ready', key: 'READY' },
            { displayName: 'Unavailable', key: 'PHONE_UNAVAILABLE' },
            { displayName: 'Unknown', key: 'UNKNOWN' },
            { displayName: 'Assigned call', key: 'ASSIGNED_CALL' },
            { displayName: 'On ACD call', key: 'ON_ACD_CALL' },
            { displayName: 'On call', key: 'ON_CALL' },
            { displayName: 'Holding', key: 'HOLDING_CALL' }
        ]
    }
];

export const callTableFilters: FilterMenuCategory<applicationFilter>[] = [
    {
        name: 'Call type',
        filters: [
            { displayName: 'Internal', key: 'internal' },
            { displayName: 'Emergency', key: 'emergency' },
            { displayName: 'Non-Emergency', key: 'nonEmergency' }
        ]
    },
    {
        name: 'Call state',
        filters: [
            { displayName: 'Connected', key: 'connected' },
            { displayName: 'Held', key: 'on_hold' },
            { displayName: 'Transferred', key: 'transferred' },
            { displayName: 'Ringing', key: 'ringing' }
        ]
    },
    {
        name: 'Call source',
        filters: [
            { displayName: 'Incoming', key: 'inbound' },
            { displayName: 'Outgoing', key: 'outbound' }
        ]
    }
];

export const historicalCallFilters: FilterMenuCategory<applicationFilter>[] = [
    {
        name: 'Call type',
        filters: [
            { displayName: 'Internal', key: 'internal' },
            { displayName: 'Emergency', key: 'emergency' },
            { displayName: 'Non-Emergency', key: 'nonEmergency' }
        ]
    },
    {
        name: 'Call state',
        filters: [
            { displayName: 'Connected', key: 'connected' },
            { displayName: 'Held', key: 'on_hold' },
            { displayName: 'Transferred', key: 'transferred' },
            { displayName: 'Ringing', key: 'ringing' },
            { displayName: 'Released', key: 'released' }
        ]
    },
    {
        name: 'Call source',
        filters: [
            { displayName: 'Incoming', key: 'inbound' },
            { displayName: 'Outgoing', key: 'outbound' }
        ]
    },
    {
        name: 'Call presence',
        filters: [
            { displayName: 'Locked', key: 'locked' },
            { displayName: 'Unlocked', key: 'unlocked' }
        ]
    }
];
