/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import {
    AbstractDataTransferObject,
    Announcement,
    Call,
    DialedContact,
    InstantMessage,
    LocalParticipant,
    QueueJoin,
    RemoteParticipant
} from 'CalltakingCoreApi';
import { Destination } from '../../directory/model/destination';
import { IntercomChat } from '../../intercom/model/intercom-chat';
import { BidResult } from '../../user/model/bid-result';
import { AnalyticsHistoricalCall, AnalyticsRecentCall } from '../model/analytics-historical-call';
import { LocationEvent } from '../../location/model/location';

export class SortFunctions {
    // default (ascending) order sort (smallest number first or "a" before "b" and "aa" before "b")
    public static nullSafeSort(a: any, b: any) {
        return Number(a === undefined) - Number(b === undefined) || Number(a === null) - Number(b === null) || +(a > b) || -(a < b);
    }

    // default ascending order (smaller/earlier) date first sort.
    public static nullSafeDateAsStringSort(a: string, b: string) {
        return SortFunctions.nullSafeSort(a ? new Date(a).getTime() : undefined, b ? new Date(b).getTime() : undefined);
    }

    // oldest is the 'smaller' timestamp (oldest first)
    public static oldestCreatedSort(a: AbstractDataTransferObject | DialedContact, b: AbstractDataTransferObject | DialedContact) {
        return SortFunctions.nullSafeDateAsStringSort(a.createdTimestamp, b.createdTimestamp);
    }

    // newest is the 'bigger' timestamp (descending) (newest first)
    public static newestCreatedSort(a: AbstractDataTransferObject | DialedContact, b: AbstractDataTransferObject | DialedContact) {
        return SortFunctions.nullSafeDateAsStringSort(b.createdTimestamp, a.createdTimestamp);
    }

    public static newestLocationEventSort(a: LocationEvent, b: LocationEvent) {
        return SortFunctions.nullSafeDateAsStringSort(b.createdDateTime, a.createdDateTime);
    }

    public static oldestLocationEventSort(a: LocationEvent, b: LocationEvent) {
        return SortFunctions.nullSafeDateAsStringSort(a.createdDateTime, b.createdDateTime);
    }

    // newest is the 'bigger' timestamp (descending) (newest first)
    public static newestEndedAnalyticsSort(a: AnalyticsHistoricalCall, b: AnalyticsHistoricalCall) {
        return SortFunctions.nullSafeDateAsStringSort(b.endDateTime, a.endDateTime);
    }

    // newest is the 'bigger' timestamp (descending) (newest first)
    public static newestEndedAnalyticsRecentSort(a: AnalyticsRecentCall, b: AnalyticsRecentCall) {
        return SortFunctions.nullSafeDateAsStringSort(b.agentEndDateTime, a.agentEndDateTime);
    }

    // oldest is the 'smaller' timestamp (oldest first)
    public static oldestTimestampSort(a: InstantMessage | IntercomChat | Announcement, b: InstantMessage | IntercomChat | Announcement) {
        return SortFunctions.nullSafeDateAsStringSort(a.timestamp, b.timestamp);
    }

    // newest is the 'bigger' timestamp (descending)
    public static newestTimestampSort(a: InstantMessage | IntercomChat | Announcement, b: InstantMessage | IntercomChat | Announcement) {
        return SortFunctions.nullSafeDateAsStringSort(b.timestamp, a.timestamp);
    }

    // newest is the 'bigger' timestamp (descending) (oldest released calls at the top)
    public static newestReleasedSort(a: Call, b: Call) {
        return SortFunctions.nullSafeDateAsStringSort(b.releasedOn, a.releasedOn);
    }

    // 'bigger' timestamp first. (descending) (most recent bid first)
    public static bidSort(a: BidResult, b: BidResult) {
        return SortFunctions.nullSafeSort(b.bid, a.bid);
    }

    // 'smallest' timestamp first. (ascending) (most recent at top)
    public static joinedOnSort(a: RemoteParticipant | LocalParticipant | QueueJoin, b: RemoteParticipant | LocalParticipant | QueueJoin) {
        return SortFunctions.nullSafeDateAsStringSort(a.joinedOn, b.joinedOn);
    }

    public static defaultCallSort(a: Call, b: Call) {
        const comparators = [SortFunctions.prioritySort, SortFunctions.oldestCreatedSort];
        return SortFunctions.compositeSort(comparators, a, b);
    }

    // boolean sort, (descending) where true sorts to the top
    public static rePresentedSort(a: Call, b: Call) {
        return SortFunctions.nullSafeSort(b.rePresented, a.rePresented);
    }

    // boolean sort, (ascending) where text calls are first
    public static textFirstSort(a: Call, b: Call) {
        return SortFunctions.nullSafeSort(b.text, a.text);
    }

    // boolean sort, (descending) where text calls are last
    public static textLastSort(a: Call, b: Call) {
        return SortFunctions.nullSafeSort(a.text, b.text);
    }

    // 'bigger' weight first. (descending) (highest weight first)
    public static acdPriorityWeightSort(a: Call, b: Call) {
        return SortFunctions.nullSafeSort(b.acdPriorityWeight, a.acdPriorityWeight);
    }

    public static acdPrioritySort(a: Call, b: Call) {
        const comparators = [SortFunctions.prioritySort, SortFunctions.rePresentedSort, SortFunctions.acdPriorityWeightSort, SortFunctions.oldestCreatedSort];
        return SortFunctions.compositeSort(comparators, a, b);
    }

    public static prioritySort(a: Call, b: Call) {
        return SortFunctions.nullSafeSort(b.priority?.priority, a.priority?.priority);
    }

    // boolean sort, (descending) where true sorts to the top
    public static isDefaultSort(a: Destination, b: Destination) {
        return SortFunctions.nullSafeSort(b.default, a.default);
    }

    public static compositeSort(comparatorList: Array<Function>, ...args: any[]) {
        for (const comparator of comparatorList) {
            const result = comparator(...args);
            if (result !== 0) {
                return result;
            }
        }
        return 0;
    }
}
