/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asBoolean'
})
export class AsBooleanPipe implements PipeTransform {

    transform(val: any): boolean {
        return Boolean(val);
    }

}
