/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TabGroupComponent } from '@msi/cobalt';
import { coerceNumberProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'msi-custom-tab-group',
    templateUrl: './custom-tab-group.component.html',
    styleUrls: ['./custom-tab-group.component.scss']
})
export class CustomTabGroupComponent extends TabGroupComponent implements AfterViewInit {
    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.tabs.changes.subscribe((value) => {
            let lastValidIndex = value.length ? value.length - 1 : 0;
            if (this.selectedIndex > lastValidIndex) {
                this.selectedIndex = lastValidIndex;
            }
        });
    }

    @Input()
    get selectedIndex(): number {
        //@ts-ignore
        return this._selectedIndex;
    }

    set selectedIndex(value: number) {
        const index = coerceNumberProperty(value, 0);

        //@ts-ignore
        if (index !== this._selectedIndex) {
            //@ts-ignore
            this._selectedIndex = value;
            this.centerCurrentlySelected(index);
            this.selectedIndexChange.emit(index);
        }
    }

    clickTab(index: number) {
        if (this.toggle && index === this.selectedIndex) {
            this.selectedIndex = -1;
        } else {
            this.selectedIndex = index;
        }
    }
}
