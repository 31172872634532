/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';
import {UserAuthenticationRecord} from 'CalltakingCoreApi';

/**
 * Filter agents and position numbers
 */
@Pipe({
  name: 'filteredAgents'
})
export class FilteredAgentsPipe implements PipeTransform {

  transform<T extends UserAuthenticationRecord>(agents: T[], filter: string) {

    if (filter && filter.length > 0) {
      return agents.filter((a) =>
          a && (((a.firstName || '')
              .concat(' ' + a.lastName || '')
              .concat(' ' + a.username))
              .toLowerCase()
              .includes(filter.toLowerCase())) ||
          String(a.positionNumber)
                .includes(filter.toLowerCase()));
    }
    return agents as T[];
  }

}
