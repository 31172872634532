import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {TimerService} from "../services/timer.service";
import {map} from "rxjs/operators";
import * as dayjs from "dayjs";
import * as duration from 'dayjs/plugin/duration';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(duration);
dayjs.extend(utc);


@Component({
  selector: 'msi-countdown-time',
    template: `{{ displayedTime$ | async }}`
})
export class CountdownTimeComponent {

    @Input()
    set endTime(value: Date) {
        this._endTime = value;
        this._displayedTime$ = this.getRemainingTimeStream();
    }

    private _endTime: Date | undefined;

    private _displayedTime$: Observable<string> | undefined;

    constructor(public timer: TimerService) { }

    get displayedTime$() {
        return this._displayedTime$;
    }

    private getRemainingTimeStream() {
        return this.timer.counter$.pipe(map(() => this.getRemainingTime()));
    }

    private getRemainingTime() {
        const now = (new Date()).toISOString();
        const startTime = now;
        const endTime = this._endTime ? this._endTime : now;
        const duration = dayjs.duration(
            dayjs(endTime).diff(dayjs(startTime))
        );
        const format = duration.hours() > 0 ? 'H:mm:ss' : 'mm:ss';
        return dayjs.utc(duration.asMilliseconds()).format(format);
    }
}
