/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Action, AudioDevicesRoles, AudioDevicesRolesInternal, IUsbButtonState, LlrRolesEnum, PhoneMode, WAM, WamMessageTypeEnum } from 'wam-wrapper';
import { IAudioConfiguration, IAudioDeviceConnectionStatus, IUsbButtonConnectionStatus, IWebUsbConnectionStatus } from 'wam-wrapper/definitions';
import {
    ccHubAudioDeviceConnectionEvent,
    ccHubInitialized,
    ccHubSerialNumber,
    ccHubUsbButtonConnectionStatus,
    ccHubUsbButtonState,
    ccWebUsbConnectionStatus,
    fetchWamConfig,
    updateDeviceEndpointBinders
} from '../+state/cchub.actions';
import {
    selectCCHubAttached,
    selectCCHubEffectInitialized,
    selectCCHubHeadset1Connected,
    selectCCHubHeadset2Connected,
    selectInitializedWamConfig,
    selectRadioHeadsetConnected,
    selectRpiExternalJackSenseState
} from '../+state/cchub.selectors';
import { filter, skip, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DevicesManager } from 'web-audio-module/dist/peripheral-configuration-tool/devices-manager';
import { IDeviceEndpointBinder } from 'web-audio-module';
import { DeviceEndpoint } from 'web-audio-module/dist/peripheral-configuration-tool/i-deviceendpoint';
import { PeripheralConfigurationTool } from 'web-audio-module/dist/peripheral-configuration-tool/pct';
import { selectLlrState, selectOffHookState } from '../+state/call.selectors';
import { CcHubAudioService } from './cc-hub-audio.service';
import { WebUsbSwdlService } from './web-usb-swdl.service';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';

@Injectable({
    providedIn: 'root'
})
export class CchubService {
    // combined vendor/product ID, this is built from constants defined in web-usb-helper.js in the web-usb-module
    public static readonly CCH_ID = '(0cad:1807)';

    // device labels (contains)
    static readonly MOTOROLA_ANALOG_HEADSET = 'Motorola Analog Headset';
    static readonly MOTOROLA_ANALOG_DESKTOP = 'Motorola Analog Desktop';
    static readonly MOTOROLA_ANALOG_EXTERNAL = 'Motorola Analog External';

    static readonly DEFAULT_AUDIO_INPUT_PERIPHERALS: (AudioDevicesRoles | AudioDevicesRolesInternal)[] = [
        AudioDevicesRoles.HEADSET_1_MIC,
        AudioDevicesRoles.HEADSET_2_MIC,
        AudioDevicesRoles.DESKTOP_MIC,
        AudioDevicesRolesInternal.RPI_IN
    ];

    static readonly DEFAULT_AUDIO_OUTPUT_PERIPHERALS: (AudioDevicesRoles | AudioDevicesRolesInternal)[] = [
        AudioDevicesRoles.HEADSET_1_PHONE_SPEAKER,
        AudioDevicesRoles.HEADSET_2_PHONE_SPEAKER,
        AudioDevicesRolesInternal.RPI_OUT
    ];
    static readonly RECORDER_AUDIO_OUTPUT_PERIPHERALS: (AudioDevicesRoles | AudioDevicesRolesInternal)[] = [
        AudioDevicesRolesInternal.LLR_1,
        AudioDevicesRolesInternal.LLR_2,
        AudioDevicesRolesInternal.LLR_3
    ];

    // Web Audio Module
    private wam!: WAM;

    private delay = (ms: number) => (x: any) => new Promise((resolve) => setTimeout(() => resolve(x), ms));

    constructor(
        private store: Store,
        private ccHubAudioService: CcHubAudioService,
        private webUsbSwdlService: WebUsbSwdlService,
        private http: HttpClient
    ) {
        this.store
            .select(selectCCHubEffectInitialized)
            .pipe(filter((init) => init))
            .subscribe(() => this.store.dispatch(fetchWamConfig()));

        this.store
            .select(selectCCHubAttached)
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                this.wam = WAM.getInstance();
                this.setupWamCallbacks();
                this.discoverDevices();
                this.wam
                    .getCChubSerialNumber()
                    .then((sn) => this.store.dispatch(ccHubSerialNumber({ sn: sn })))
                    .catch((reason) => console.error(`Failed to get CCHub Serial Number: ${JSON.stringify(reason, undefined, 1)}`));
                this.store
                    .select(selectInitializedWamConfig)
                    .pipe(
                        filter((val) => !!val),
                        take(1)
                    )
                    .subscribe((config) =>
                        this.wam
                            .applyConfig(JSON.parse(JSON.stringify(config)) as IAudioConfiguration)
                            // TODO CCHub applyConfig fails to wait for initializeAdvancedConsoleConfiguration to resolve so rpiArbitration isn't set immediately. workaround by delaying initialization
                            .then(this.delay(1000))
                            .then(() => this.monitorRpiDevices())
                            .then(() => this.store.dispatch(ccHubInitialized()))
                            .then(() => this.ccHubAudioService.init())
                            .then(() => this.webUsbSwdlService.init())
                            .catch((e) => console.error(`WAM apply config failure: ${JSON.stringify(e, undefined, 1)}`))
                    );
                this.monitorRpiJackSenseState();
                this.monitorCcHubLlrState();
                this.monitorCcHubOffHookState();
                this.monitorCcHubHeadsets();
            });
    }

    /** Monitor RPI (radio headset) device presence and add/remove as appropriate **/
    private monitorRpiDevices() {
        this.store.select(selectRadioHeadsetConnected).subscribe((rpiEnabled) => (rpiEnabled ? this.enableRpiMediaDevices() : this.disableRpiMediaDevices()));
    }

    private setupWamCallbacks() {
        this.wam.setOnMessageCallback((iWamMessage) => {
            switch (iWamMessage.type) {
                case WamMessageTypeEnum.USB_BUTTON_CONNECTION_STATUS:
                    return this.store.dispatch(ccHubUsbButtonConnectionStatus({ event: iWamMessage.body as IUsbButtonConnectionStatus }));
                case WamMessageTypeEnum.USB_BUTTON_STATE:
                    return this.store.dispatch(ccHubUsbButtonState({ event: iWamMessage.body as IUsbButtonState }));
                case WamMessageTypeEnum.AUDIO_DEVICE_CONNECTION_STATUS:
                    let audioDeviceConnectionStatus = iWamMessage.body as IAudioDeviceConnectionStatus;
                    if (audioDeviceConnectionStatus.peripheral.deviceendpoint.label.includes(CchubService.CCH_ID)) {
                        this.store.dispatch(ccHubAudioDeviceConnectionEvent({ event: JSON.parse(JSON.stringify(audioDeviceConnectionStatus)) }));
                    }
                    return;
                case WamMessageTypeEnum.WEBUSB_CONNECTION_STATUS:
                    return this.store.dispatch(ccWebUsbConnectionStatus({ event: iWamMessage.body as IWebUsbConnectionStatus }));
                case WamMessageTypeEnum.PLAY_TONE_STATUS:
                    // ignore
                    return;
                default:
                    return console.debug(`Unhandled IWamMessage: ${JSON.stringify(iWamMessage, undefined, 1)}`);
            }
        });
        this.wam.setOnErrorCallback((e) => {
            console.error(`wam error: ${JSON.stringify(e, undefined, 1)}`);
            if (e.code === 503 && e.message === 'USB Device error: NotFoundError') {
                return this.store.dispatch(ccWebUsbConnectionStatus({ event: { connectionState: false } }));
            }
        });
    }

    private discoverDevices() {
        DevicesManager.getInstance()
            .init()
            .then((devices) => {
                this.store.dispatch(
                    updateDeviceEndpointBinders({
                        endpointBinders: devices
                            .filter((device) => device.label.includes(CchubService.CCH_ID))
                            .map((deviceEndpoint) => {
                                return {
                                    isConnected: deviceEndpoint.isConnected,
                                    peripheral: this.getDeviceRole(deviceEndpoint),
                                    deviceendpoint: deviceEndpoint
                                } as IDeviceEndpointBinder;
                            })
                    })
                );
            })
            .catch((e) => console.error(`Failed to discover CCHub devices: ${e}`));
    }

    private enableRpiMediaDevices() {
        console.debug('Enabling RPI input/output');
        let radioInput: DeviceEndpoint = PeripheralConfigurationTool.getInstance().getDeviceEndpointByPeripheral('RPI_IN');
        let radioOutput: DeviceEndpoint = PeripheralConfigurationTool.getInstance().getDeviceEndpointByPeripheral('RPI_OUT');
        radioInput.action = Action.Added;
        radioOutput.action = Action.Added;
        radioInput.isConnected = true;
        radioOutput.isConnected = true;
        // @ts-ignore
        DevicesManager.processChangedDevices([radioInput, radioOutput], 1 /*AudioDeviceType.UsbEmbeddedDevice*/);
    }

    private disableRpiMediaDevices() {
        console.debug('Disabling RPI input/output');
        let radioInput: DeviceEndpoint = PeripheralConfigurationTool.getInstance().getDeviceEndpointByPeripheral('RPI_IN');
        let radioOutput: DeviceEndpoint = PeripheralConfigurationTool.getInstance().getDeviceEndpointByPeripheral('RPI_OUT');
        radioInput.isConnected = false;
        radioOutput.isConnected = false;
        radioInput.action = Action.Removed;
        radioOutput.action = Action.Removed;
        // @ts-ignore
        DevicesManager.processChangedDevices([radioInput, radioOutput], 1 /*AudioDeviceType.UsbEmbeddedDevice*/);
    }

    /**
     * set device roles for headsets since it isn't done automatically...
     * **/
    private getDeviceRole(deviceEndpoint: DeviceEndpoint): AudioDevicesRoles | AudioDevicesRolesInternal {
        if (deviceEndpoint.label.includes(CchubService.MOTOROLA_ANALOG_HEADSET)) {
            if (deviceEndpoint.kind === 'audioinput') {
                if (deviceEndpoint.channel === 1) {
                    return AudioDevicesRoles.HEADSET_1_MIC;
                }
                if (deviceEndpoint.channel === 2) {
                    return AudioDevicesRoles.HEADSET_2_MIC;
                }
            }
            if (deviceEndpoint.kind === 'audiooutput') {
                if (deviceEndpoint.channel === 1) {
                    return AudioDevicesRoles.HEADSET_1_PHONE_SPEAKER;
                }
                if (deviceEndpoint.channel === 2) {
                    return AudioDevicesRoles.HEADSET_2_PHONE_SPEAKER;
                }
                if (deviceEndpoint.channel === 3) {
                    return AudioDevicesRoles.HEADSET_1_RADIO_SPEAKER;
                }
                if (deviceEndpoint.channel === 4) {
                    return AudioDevicesRoles.HEADSET_2_RADIO_SPEAKER;
                }
            }
        }
        if (deviceEndpoint.label.includes(CchubService.MOTOROLA_ANALOG_DESKTOP) && deviceEndpoint.kind === 'audioinput') {
            return AudioDevicesRoles.DESKTOP_MIC;
        }
        if (deviceEndpoint.label.includes(CchubService.MOTOROLA_ANALOG_EXTERNAL)) {
            if (deviceEndpoint.kind === 'audioinput' && deviceEndpoint.channel === 1) {
                return AudioDevicesRolesInternal.RPI_IN;
            }
            if (deviceEndpoint.kind === 'audiooutput' && deviceEndpoint.channel === 1) {
                return AudioDevicesRolesInternal.RPI_OUT;
            }
        }
        return '' as AudioDevicesRoles;
    }

    public requestWAMConfig() {
        return this.http.get<IAudioConfiguration>(`assets/data/wam.json`, { observe: 'body', responseType: 'json' });
    }

    private monitorRpiJackSenseState() {
        this.store.pipe(selectRpiExternalJackSenseState).subscribe((on) =>
            on
                ? this.wam
                      .enableRpiToThirdPartyRadioConsole(PhoneMode.EXTERNAL_JACK_SENSE)
                      .then(() => console.debug(`Enabled RPI EXTERNAL_JACK_SENSE Relay`))
                      .catch((e) => console.error(`Enabled RPI EXTERNAL_JACK_SENSE Relay Failed: ${JSON.stringify(e, undefined, 1)}`))
                : this.wam
                      .disableRpiToThirdPartyRadioConsole(PhoneMode.EXTERNAL_JACK_SENSE)
                      .then(() => console.debug(`Disabled RPI EXTERNAL_JACK_SENSE Relay`))
                      .catch((e) => console.error(`Disabled RPI EXTERNAL_JACK_SENSE Relay Failed: ${JSON.stringify(e, undefined, 1)}`))
        );
    }

    private monitorCcHubOffHookState() {
        this.store.pipe(selectOffHookState).subscribe((on) =>
            on
                ? this.wam
                      .enableRpiToThirdPartyRadioConsole(PhoneMode.OFF_HOOK_SENSE)
                      .then(() => console.debug(`Enabled OFF_HOOK_SENSE Relay`))
                      .catch((e) => console.error(`Enabled OFF_HOOK_SENSE Relay Failed: ${JSON.stringify(e, undefined, 1)}`))
                : this.wam
                      .disableRpiToThirdPartyRadioConsole(PhoneMode.OFF_HOOK_SENSE)
                      .then(() => console.debug(`Disabled RPI OFF_HOOK_SENSE Relay`))
                      .catch((e) => console.error(`Disabled RPI OFF_HOOK_SENSE Relay Failed: ${JSON.stringify(e, undefined, 1)}`))
        );
    }

    private monitorCcHubLlrState() {
        this.store.pipe(selectLlrState).subscribe((on) => {
            if (on) {
                this.wam
                    .enableLlrLed(LlrRolesEnum.LLR_1)
                    .then(() => console.debug(`Enabled LLR_1 Output Relay`))
                    .catch((e) => console.error(`Failed to enabled LLR_1 Output Relay: ${JSON.stringify(e, undefined, 1)}`));
                this.wam
                    .enableLlrLed(LlrRolesEnum.LLR_2)
                    .then(() => console.debug(`Enabled LLR_2 Output Relay`))
                    .catch((e) => console.error(`Failed to enabled LLR_2 Output Relay: ${JSON.stringify(e, undefined, 1)}`));
                this.wam
                    .enableLlrLed(LlrRolesEnum.LLR_3)
                    .then(() => console.debug(`Enabled LLR_3 Output Relay`))
                    .catch((e) => console.error(`Failed to enabled LLR_3 Output Relay: ${JSON.stringify(e, undefined, 1)}`));
            } else {
                this.wam
                    .disableLlrLed(LlrRolesEnum.LLR_1)
                    .then(() => console.debug(`Disabled LLR_1 Output Relay`))
                    .catch((e) => console.error(`Failed to disable LLR_1 Output Relay: ${JSON.stringify(e, undefined, 1)}`));
                this.wam
                    .disableLlrLed(LlrRolesEnum.LLR_2)
                    .then(() => console.debug(`Disabled LLR_2 Output Relay`))
                    .catch((e) => console.error(`Failed to disable LLR_2 Output Relay: ${JSON.stringify(e, undefined, 1)}`));
                this.wam
                    .disableLlrLed(LlrRolesEnum.LLR_3)
                    .then(() => console.debug(`Disabled LLR_3 Output Relay`))
                    .catch((e) => console.error(`Failed to disable LLR_3 Output Relay: ${JSON.stringify(e, undefined, 1)}`));
            }
        });
    }

    private monitorCcHubHeadsets() {
        this.store
            .select(selectCCHubHeadset1Connected)
            .pipe(skip(1))
            .subscribe((connected) =>
                connected
                    ? this.store.dispatch(displayToastNotification({ level: ToastType.success, message: 'Headset 1 Connected.' }))
                    : this.store.dispatch(displayToastNotification({ level: ToastType.warning, message: 'Headset 1 Disconnected.' }))
            );
        this.store
            .select(selectCCHubHeadset2Connected)
            .pipe(skip(1))
            .subscribe((connected) =>
                connected
                    ? this.store.dispatch(displayToastNotification({ level: ToastType.success, message: 'Headset 2 Connected.' }))
                    : this.store.dispatch(displayToastNotification({ level: ToastType.warning, message: 'Headset 2 Disconnected.' }))
            );
        this.store
            .select(selectRadioHeadsetConnected)
            .pipe(skip(1))
            .subscribe((connected) =>
                connected
                    ? this.store.dispatch(displayToastNotification({ level: ToastType.success, message: 'Radio Headset Connected.' }))
                    : this.store.dispatch(displayToastNotification({ level: ToastType.warning, message: 'Radio Headset Disconnected.' }))
            );
    }
}
