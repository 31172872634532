/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { applicationFilter, AudioDeviceSettings, CallListConfiguration, FilterState, SupervisorAgencySettings, UserSettings } from '../model/settings.model';
import { Theme } from '../model/theme';
import { SoftwareVersion } from '../model/software-version';

export const settingsEffectsInitialized = createAction('[Settings] Effects Initialized');
export const fetchAgencySettings = createAction('[Settings] Fetch All Agency');
export const fetchAgencySettingsSuccess = createAction('[Settings] Fetch All Agency Success', props<{ settings: SupervisorAgencySettings }>());
export const fetchAgencySettingsFailure = createAction('[Settings] Fetch All Agency Failure', props<{ payload: string }>());
export const fetchUserSettings = createAction('[Settings] Fetch All User');
export const fetchUserSettingsSuccess = createAction('[Settings] Fetch All User Success', props<{ settings: UserSettings }>());
export const fetchUserSettingsFailure = createAction('[Settings] Fetch All User Failure', props<{ payload: string }>());
export const updateUserSettings = createAction('[Settings] Update User Settings', props<{ settings: UserSettings }>());
export const updateUserSettingsSuccess = createAction('[Settings] Update User Settings Success', props<{ settings: UserSettings }>());
export const updateUserSettingsFailure = createAction('[Settings] Update User Settings Failure', props<{ payload: string }>());
export const toggleTheme = createAction('[Settings] Toggle Theme');
export const setTheme = createAction('[Settings] Set Theme', props<{ theme: Theme }>());
export const updateFilters = createAction('[Settings] Update Filters', props<{ filterKey: keyof FilterState; filters: applicationFilter[] }>());
export const updateAudioSettings = createAction('[Settings] Update Audio', props<{ settings: Partial<AudioDeviceSettings> }>());

// Subject to change when api is ready
export const fetchCallTableColumns = createAction('[Settings] Fetch Call Table Columns');
export const fetchCallTableColumnsSuccess = createAction('[Settings] Fetch Call Table Columns Success', props<{ config: CallListConfiguration }>());
export const fetchCallTableColumnsFailure = createAction('[Settings] Fetch Call Table Columns Failure', props<{ payload: string }>());

export const fetchVersions = createAction('[Settings] Fetch Skipper Software Versions');
export const fetchVersionsSuccess = createAction('[Settings] Fetch Skipper Software Versions Success', props<{ versions: SoftwareVersion[] }>());
export const fetchVersionsFailure = createAction('[Settings] Fetch Skipper Software Versions Failure', props<{ payload: string }>());
