/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { VestaKeycloakTokenParsed } from './user/model/vesta-keycloak-token-parsed';
import { EnvironmentService } from './core/services/environment.service';
import { AuthenticationService, HeaderTokenService } from '@msi/commandcentral-user-authentication';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    private static readonly ALLOWED_CLIENT_PERMISSIONS = ['call-handling-ui', 'call-handling-ui-tech'];
    // Used by the keycloak spi to filter applicable roles.
    private static readonly KEYCLOAK_SPI_ROLE_HINT = 'CALLTAKING';
    // delimiter separating application part and browser identifier
    private static readonly LOGIN_HINT_DELIMITER = "__";

    constructor(
        public router: Router,
        private readonly keycloakService: KeycloakService,
        private env: EnvironmentService,
        private preventInfiniteSpinnerRaceInjection: HeaderTokenService,
        private authenticationService: AuthenticationService
    ) {}

    public canAccess(): Promise<boolean> {
        return this.isKeycloakAccessAllowed().then((keycloakActivated) => {
            console.log(`keycloak is activated: ${keycloakActivated}`);
            if (keycloakActivated) {
                if (this.env.environment.featureFlags && this.env.environment.featureFlags.ccAdminIntegrated) {
                    return this.isAdminAuthenticated();
                } else {
                    return Promise.resolve(this.checkPermission());
                }
            } else {
                return Promise.resolve(false);
            }
        });
    }

    private getEncodedBrowserIdentifierWithDelimiter(): string {
        let browserIdentifier = localStorage.getItem('browserIdentifier');
        if (browserIdentifier) {
            return AuthGuard.LOGIN_HINT_DELIMITER + btoa(browserIdentifier);
        } else {
            return '';
        }
    }

    private async isKeycloakAccessAllowed(): Promise<boolean> {
        const authenticated = await this.keycloakService.isLoggedIn();

        if (!authenticated) {
            await this.keycloakService.login({
                redirectUri: window.location.href,
                loginHint: AuthGuard.KEYCLOAK_SPI_ROLE_HINT + this.getEncodedBrowserIdentifierWithDelimiter()
            });
        }

        return true;
    }

    private isAdminAuthenticated(): Promise<boolean> {
        return this.authenticationService.authenticate().then(
            (token) => {
                console.log('Authentication result, admin token', token);

                if (!this.checkPermission()) {
                    return false;
                }

                return !!token;
            },
            (reason) => {
                console.log('Failed to authenticate', reason);
                return false;
            }
        );
    }

    private checkPermission() {
        const parsedToken = this.keycloakService.getKeycloakInstance().tokenParsed as VestaKeycloakTokenParsed;
        const permissions = parsedToken?.preferred_role_permissions;

        if (!permissions?.some((permission) => AuthGuard.ALLOWED_CLIENT_PERMISSIONS.includes(permission))) {
            this.router.navigate(['/unauthorized']);
            return false;
        }

        return true;
    }
}
