/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { MediaConnection } from '../../core/model/media-connection';
import { RxStompState } from '@stomp/rx-stomp';
import { Call } from 'CalltakingCoreApi';

export const mediaEffectsInitialized = createAction('[Media Connection] Effects Initialized');
export const createMediaConnection = createAction('[Media Connection] Create Connection', props<{ mediaConnection: MediaConnection }>());
export const updateMediaConnectionRecord = createAction('[Media Connection] Update Media Connection', props<{ mediaConnection: Partial<MediaConnection> }>());
export const updateMediaConnectionsExtraHeaders = createAction('[Media Connection] Update Media Connection Headers', props<{ headers: string[] }>());
export const deleteMediaConnections = createAction('[Media Connection] Delete Connections');
export const getUserMediaSuccess = createAction('[Media Connection] Get User Media Success');
export const updateMediaDevices = createAction('[Media Connection] User Media Devices', props<{ devices: MediaDeviceInfo[] }>());
export const selectedUserInputDevice = createAction('[Media Connection] Select User Input Device', props<{ deviceId: string | undefined }>());
export const selectedUserInputDeviceFailed = createAction('[Media Connection] Select User Input Device Failed', props<{ payload: string }>());
export const selectedUserOutputDevice = createAction('[Media Connection] Select User Output Device', props<{ deviceId: string | undefined }>());
export const selectedUserOutputDeviceFailed = createAction('[Media Connection] Select User Output Device Failed', props<{ payload: string }>());
export const selectedAlertOutputDevice = createAction('[Media Connection] Select Alert Output Device', props<{ deviceId: string | undefined }>());
export const selectedAlertOutputDeviceFailed = createAction('[Media Connection] Select Alert Output Device Failed', props<{ payload: string }>());
export const selectedIrrOutputDevice = createAction('[Media Connection] Select IRR Output Device', props<{ deviceId: string | undefined }>());
export const selectedIrrOutputDeviceFailed = createAction('[Media Connection] Select IRR Output Device Failed', props<{ payload: string }>());
export const getUserMediaFail = createAction('[Media Connection] Get User Media Fail', props<{ error: string }>());
export const setCallVolume = createAction('[Media Connection] Set Call Volume', props<{ volume: number }>());
export const setAlertVolume = createAction('[Media Connection] Set Alert Volume', props<{ volume: number }>());
export const setIrrVolume = createAction('[Media Connection] Set IRR Volume', props<{ volume: number }>());
export const setSipDebug = createAction('[Media Connection] Set SIP Debug', props<{ enable: boolean }>());
export const updateStompConnectionState = createAction('[Media Connection] Update STOMP Connection State', props<{ rxStompState: RxStompState }>());
export const tearDownMedia = createAction('[Media Connection] Media Teardown due to Re-Presentation detection', props<{ callId: string, clusterName: string, represented: Call }>());
export const tearDownMediaSuccess = createAction('[Media Connection] Media Teardown Success', props<{ represented: Call }>());
export const tearDownMediaFailure = createAction('[Media Connection] Media Teardown Failure');

