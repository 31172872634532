/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { DeviceAudioContextInterface } from './device-audio-context-interface';

export class OutputDeviceInterface extends DeviceAudioContextInterface {
    protected readonly mixerNode: GainNode;
    protected remoteMediaSourceNode: MediaStreamAudioSourceNode | undefined;
    protected localMediaSourceNodes: MediaStreamAudioSourceNode[] = [];
    protected volume = 5;

    constructor(device: MediaDeviceInfo, channel = 0) {
        super(device, channel);
        this.mixerNode = this.audioContext.createGain();
    }

    public init() {
        // @ts-ignore
        return this.audioContext.setSinkId(this.device.deviceId)
            .catch((e: any) => this.ignoreDefaultSinkError(e))
            .then(() => console.debug(`Sinking output to device: ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''} successful`))
            .then(() => this.checkContext())
            .then(() => this.connect())
            .catch((e: any) => console.error(`Unable to sink device: ${this.device.label} to audio context {}`, e));
    }

    protected connect() {
        this.mixerNode.connect(this.audioContext.destination);
    }

    public addLocalMediaStream(mediaStream: MediaStream) {
        if (!this.localMediaSourceNodes.map((source) => source.mediaStream.id).includes(mediaStream.id)) {
            console.debug(`Attaching local media stream ${mediaStream.id} to ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            const source = this.audioContext.createMediaStreamSource(mediaStream);
            source.connect(this.mixerNode);
            this.localMediaSourceNodes.push(source);
        }
    }

    public removeLocalMediaStream(mediaStream: MediaStream) {
        const node = this.localMediaSourceNodes.find((source) => source.mediaStream.id === mediaStream.id);
        if (node) {
            console.debug(`Removing local media stream ${mediaStream.id} from ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            node.disconnect(this.mixerNode);
            this.localMediaSourceNodes = this.localMediaSourceNodes.filter((source) => source.mediaStream.id !== mediaStream.id);
        }
    }

    public setLocalMediaStreams(mediaStreams: MediaStream[]) {
        let nodesToRemove = this.localMediaSourceNodes.filter((node) => !mediaStreams.map((mediaStream) => mediaStream.id).includes(node.mediaStream.id));
        let streamsToAdd = mediaStreams.filter((mediaStream) => !this.localMediaSourceNodes.map((node) => node.mediaStream.id).includes(mediaStream.id));

        nodesToRemove.forEach((source) => {
            console.debug(`Removing local media stream ${source.mediaStream.id} from ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            source.disconnect(this.mixerNode);
        });
        this.localMediaSourceNodes = this.localMediaSourceNodes.filter((node) => !nodesToRemove.includes(node));

        let nodesToAdd = streamsToAdd.map((mediaStream) => this.audioContext.createMediaStreamSource(mediaStream));
        nodesToAdd.forEach((source) => {
            console.debug(`Attaching local media stream ${source.mediaStream.id} to ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            source.connect(this.mixerNode);
        });
        this.localMediaSourceNodes.push(...nodesToAdd);
    }

    public setRemoteMediaStream(mediaStream: MediaStream | undefined) {
        if (mediaStream) {
            console.debug(`Attaching remote media stream ${mediaStream.id} to ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            this.remoteMediaSourceNode = this.audioContext.createMediaStreamSource(mediaStream);
            this.remoteMediaSourceNode.connect(this.mixerNode);
        } else {
            this.removeRemoteMediaStream();
        }
    }

    protected removeRemoteMediaStream() {
        if (this.remoteMediaSourceNode) {
            console.debug(`Disconnecting remote media stream ${this.remoteMediaSourceNode.mediaStream.id} from ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
            this.remoteMediaSourceNode.disconnect(this.mixerNode);
            this.remoteMediaSourceNode = undefined;
        }
    }

    public setAudioVolume(volume: number) {
        this.volume = volume / 5;
        console.debug(`Setting gain to ${this.volume} on ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`);
        if (this.mixerNode) this.mixerNode.gain.value = this.volume;
    }

    public destroy() {
        this.removeRemoteMediaStream();
        this.localMediaSourceNodes.forEach((source) => source.disconnect(this.mixerNode));
        this.mixerNode.disconnect();
        this.audioContext
            .close()
            .then(() => console.debug(`Destroyed audio context associated with ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`))
            .catch(() =>
                console.error(`Failed to destroy audio context associated with ${this.device.label} ${this.channel ? 'channel: ' + this.channel : ''}`)
            );
    }

    private ignoreDefaultSinkError(e: any) {
        if (this.device.deviceId === 'default') {
            return;
        } else {
            throw e;
        }
    }
}
