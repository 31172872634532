/*
 * COPYRIGHT MOTOROLA SOLUTIONS, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Component, TemplateRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { EnvironmentService } from '../core/services/environment.service';
import { VestaKeycloakTokenParsed } from '../user/model/vesta-keycloak-token-parsed';
import { Role } from '../configuration/model/layout-settings';
import { RolePermission } from '../core/model/role-permission';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService, MsiModalRef } from '@msi/cobalt';
import { SkipperService } from '../core/services/skipper.service';
import { HeaderLoginService } from '@msi/commandcentral-user-authentication';

@Component({
    selector: 'msi-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
    public appName: string;
    public token: VestaKeycloakTokenParsed;
    public filteredRoles: Role[];
    private msiModalRef: MsiModalRef;

    constructor(
        public router: Router,
        public env: EnvironmentService,
        public keycloakService: KeycloakService,
        private modalService: ModalService,
        private skipperService: SkipperService,
        private headerLoginService: HeaderLoginService
    ) {
        this.appName = env.environment.APP_NAME || 'VESTA NXT';
        this.token = this.keycloakService.getKeycloakInstance()?.tokenParsed as VestaKeycloakTokenParsed;
        console.log(`token`, this.token);
        const currentRole = this.token?.preferred_role;
        const roles$ = this.skipperService.fetchUserRolesByPermission(RolePermission['call-handling-ui']);
        roles$.pipe(take(1)).subscribe((roles) => {
            this.filteredRoles = roles.filter((role) => this.token.roles.includes(role.roleName) && role.roleName !== currentRole);
        });
    }

    public changeRole(roleName: string) {
        this.skipperService.updateCurrentRole(roleName, this.token.sub).subscribe(() => {
            this.redirectToHome();
        });
    }

    public redirectToHome() {
        this.router.navigate(['/']).then(() => {
            window.location.reload();
        });
    }

    public signOut(tpl: TemplateRef<any>) {
        this.msiModalRef = this.modalService.open(tpl, {
            hasBackdrop: true,
            disableClose: true,
            minWidth: '25vw'
        });
    }

    public cancel() {
        this.msiModalRef.close();
    }

    public confirm() {
        this.headerLoginService.logout();
    }
}
