/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CustomOverlayContainer extends OverlayContainer {

    /**
     * Create overlay container and append to ElementRef from directive
     */
    public customCreateContainer(element: HTMLElement): void {
        let container = document.createElement('div');
        container.classList.add('cdk-overlay-container');
        element.appendChild(container);
        this._containerElement = container;
    }

    public setContainerElement(element: HTMLElement) {
        this._containerElement = element;
    }
}
