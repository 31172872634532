/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {MSI_SORT_DIRECTION} from "@msi/cobalt";

export interface Sortable<T> {
    selectedSort: SortFunction<T>;
    doSort: SortSelectFunction<T>;
}

export type SortFunction<T> = (a: T, b: T) => number;

export type SortSelectFunction<T> = (direction: MSI_SORT_DIRECTION, field?: keyof T) => void;

export const nullSafeSort = (a: any, b: any, direction: MSI_SORT_DIRECTION) => {
    let ascending = Number(a === undefined) - Number(b === undefined)
        || Number(a === null) - Number(b === null)
        || + (a > b)
        || - (a < b);
    return direction === 'asc' ? ascending : -ascending;
};
