/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'asDate'
})
export class AsDatePipe implements PipeTransform {

    transform(timestamp: number | string): Date {
        return new Date(timestamp);
    }

}
