/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Call } from "CalltakingCoreApi";

@Pipe({
  name: 'callIcon'
})
export class CallIconPipe implements PipeTransform {

    transform(call: Call, username?: string): any {
        if (!call) {
            return 'ic_check';
        } else if (call.rtt) {
            return 'ic_call_rtt';
        } else if (call.tdd) {
            return 'ic_call_rtt_tty';
        } else if (call.text) {
            return 'ic_conversation';
        } else {
            return 'ic_call';
        }
    }

}
