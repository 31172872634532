/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import {Location} from "../../location/model/location";

@Pipe({
  name: 'handlebarsTemplate'
})
export class HandlebarsTemplatePipe implements PipeTransform {

  transform(handleBarTemplate: string, object: Location): unknown {
      let result = '';
      try {
          // @ts-ignore
          result = Handlebars.templates[handleBarTemplate](object);
      } catch(e) {
          console.error(`Failed to parse handlebars template: ${handleBarTemplate}`, e);
      }
      return result;
  }

}
