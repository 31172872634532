/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { EnvironmentService } from '../services/environment.service';
import { KeycloakService } from 'keycloak-angular';
import { IEnvironment } from '../../../assets/config/environment';
import { firstValueFrom } from 'rxjs';
import { SessionGuardService } from '../../session-guard.service';

export function AppInitializer(envService: EnvironmentService, keycloak: KeycloakService, sessionGuardService: SessionGuardService) {
    return () =>
        new Promise(async (resolve, reject) => {
            let config: IEnvironment;
            try {
                config = await firstValueFrom(envService.loadConfiguration());
            } catch (error) {
                console.error('err', error);
                reject('Failure to load client configuration.');
                return;
            }
            await sessionGuardService.canInitialize().catch((reason) => {
                reject(reason);
                return;
            });

            try {
                await keycloak.init({
                    config: {
                        url: config.AUTH_URL,
                        realm: config.AUTH_REALM,
                        clientId: config.AUTH_CLIENT_ID
                    },
                    initOptions: {
                        checkLoginIframe: true,
                        checkLoginIframeInterval: 5
                    },
                    enableBearerInterceptor: false,
                    bearerExcludedUrls: ['https://idm.pi.commandcentral.com', 'https://admin-api.pi.commandcentral.com', 'idm.imw.motorolasolutions.com', 'https://admin-api.usgov.commandcentral.com']
                });
                console.log('resolving keycloak...');
                resolve('');
            } catch (err) {
                console.error(`Error during initialization: ${JSON.stringify(err)}`);
                reject('Failure to resolve keycloak.');
            }
        });
}
