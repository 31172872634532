/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Call, CallQueue, QueueJoin } from 'CalltakingCoreApi';
import { SortFunctions } from '../util/sort-functions';

@Pipe({
    name: 'queues'
})
export class QueuesPipe implements PipeTransform {
    transform(call: Call, queueMap: Record<string, CallQueue>): string {
        let queues = call ? [...call.queueJoins] : [];
        let hasQueue = queues.length;
        let inQueue = hasQueue && queues.filter((queue) => !queue.leftOn).length;
        return hasQueue ? (inQueue ? this.getCurrentActiveQueues(queues, queueMap) : this.getLastActiveQueue(queues, queueMap)) : call.type.toLowerCase();
    }

    private getCurrentActiveQueues(queues: QueueJoin[], queueMap: Record<string, CallQueue>) {
        return queues
            .filter((queue) => !queue.leftOn)
            .map((queue) => queueMap[queue.queueName] ? queueMap[queue.queueName].displayName : queue.queueName)
            .join(' | ');
    }

    private getLastActiveQueue(queues: QueueJoin[], queueMap: Record<string, CallQueue>) {
        return queues
            .sort(SortFunctions.joinedOnSort)
            .reverse()
            .map((queue) => queueMap[queue.queueName] ? queueMap[queue.queueName].displayName : queue.queueName)[0];
    }
}
