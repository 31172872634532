/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import {SwUpdate} from "@angular/service-worker";
import {ApplicationRef, Injectable} from "@angular/core";
import {interval} from "rxjs";
import {filter} from "rxjs/operators";
import {ToastMode, ToastService} from "@msi/cobalt";

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    constructor(private appRef: ApplicationRef, private updates: SwUpdate, private toastService: ToastService) {

        // Log update events
        this.updates.versionUpdates.subscribe((event) => console.log(`Version update event: ${JSON.stringify(event)}`));

        interval(60 * 1000).subscribe(() => this.updates.checkForUpdate());

        // Trigger update prompt when update is discovered.
        this.updates.versionUpdates.pipe(filter((event) => event.type === 'VERSION_READY')).subscribe(() => this.showUpdateNotification());
    }

    private showUpdateNotification() {
        this.toastService.warning("Update available", { click: () => this.applyUpdate(), text: 'Reload' }, {
            autoDismiss: false,
            closeButton: true,
            mode: ToastMode.Text
        });
    }

    private applyUpdate() {
        this.updates.activateUpdate().then(() => document.location.reload());
    }
}
