/*
 * *****************************************************************************
 *     Copyright (C) 2024 Motorola Solutions, Inc.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import * as JsSIP from 'jssip';
import { Call } from 'CalltakingCoreApi';
import { LocationEvent } from '../../location/model/location';

export class CallbackUtil {

    public static getCallback(call: Call, location: LocationEvent, username: string) {
        const pAssertedIdentity = call?.passertedIdentity?.number;
        const adrCallback = location?.adrCallback;
        const callerId = call?.callerId?.number;
        const pani = location?.pani;
        const userCallback = call?.participants[username]?.callback;

        return pAssertedIdentity ?? adrCallback ?? callerId ?? pani ?? userCallback ?? call?.callback;
    }

    private static phoneRegex = new RegExp('webphone-(\\d+)\\$(\\S+)');

    public static parseUserFromSipUri(callback: string): string {
        let parsed = JsSIP.URI.parse(callback);

        if (parsed && CallbackUtil.phoneRegex.test(parsed.user)) {
            return parsed.user.match(CallbackUtil.phoneRegex)[1];
        } else if (parsed) {
            return parsed.user;
        }
        return callback;
    }
}
