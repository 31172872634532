/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */


import * as JsSIP from 'jssip';

export class MediaFunctions {

    public static parseSipUri(uri: string): JsSIP.URI {
        return JsSIP.URI.parse(uri);
    }

    public static isSipUri(uri: string) {
        return Boolean(MediaFunctions.parseSipUri(uri));
    }

    public static getHostFromUri(uri: string) {
        return MediaFunctions.parseSipUri(uri)?.host;
    }

    public static getSipUri(uri: string) {
        return JsSIP.URI.parse(`sip:${uri}`)?.toString();
    }

    public static getContactSipUri(uri: string) {
        let contact = JsSIP.URI.parse(`sip:${uri}`);
        contact?.setParam('transport', 'wss');
        return contact?.toString();
    }

}
