/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Call, CallStatus, UserAuthenticationRecord } from "CalltakingCoreApi";
import { CallFunctions } from "../../call/util/call-functions";

@Pipe({
  name: 'agentCallStatus'
})
export class AgentCallStatusPipe implements PipeTransform {

  transform(call: Call, user: UserAuthenticationRecord): CallStatus | null {
    return CallFunctions.getCallStatus(call, user);
  }

}
